




import React, { Component } from 'react';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { API_URL, THIS_URL, URL } from '../../variables';

import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import TabPanel from "../ReusableComponents/TabPanel";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import Candidatos from './Candidatos';


class ShowEntrevista extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            processo: {},
            entrevista: {},
            prova: {},
            show_more: false,

            activeTab: 0
        };

    }


    get_entrevista(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_entrevista/${id}?show=1`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_prova_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, entrevista: resp.entrevista, processo: resp.entrevista.processo,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_entrevista(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                search={'?tab=9'}
                                to={`/processos/show/${this.state.processo.id}`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.entrevista.name}</h4>
                        </div>
                        <p style={{ whiteSpace: 'pre-line' }}><b style={{ color: 'black', fontSize: 18 }}>Descrição:</b> <div dangerouslySetInnerHTML={{ __html: this.state.entrevista.descricao }}></div></p>

                        <p style={{}}><b style={{ color: 'black', fontSize: 18 }}>Tópicos:</b> </p>
                        <div style={{ marginTop: 10 }}>
                            {this.state.entrevista.topicos.map((item2, id2) => (
                                <div key={id2} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}><div style={{ background: 'black', borderRadius: 100, minWidth: 6, minHeight: 6, marginRight: 5 }}></div> <span>{item2.text}</span></div>
                            ))}
                        </div>
                        <p style={{}}><b style={{ color: 'black', fontSize: 18 }}>Critérios:</b> </p>

                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Código do Critério</th>
                                            <th>Texto do Critério</th>
                                            <th>Nota mínima</th>
                                            <th>Nota máxima</th>
                                        </tr>

                                    </thead>
                                    <tbody >

                                        {this.state.entrevista.criterios.map((item, id) => (
                                            <tr key={id}>
                                                <td>
                                                    {item.code}

                                                </td>
                                                <td>
                                                    {item.text}

                                                </td>
                                                <td>
                                                    {item.min_score}

                                                </td>
                                                <td>
                                                    {item.max_score}

                                                </td>
                                            </tr>
                                        ))}

                                        {this.state.entrevista.criterios.length == 0 && <tr >
                                            <td colSpan="4">Nenhum critério adicionado</td>
                                        </tr>}



                                    </tbody>
                                </table>
                            </div>
                        </div>



                        {/* <p className="ver_regras" onClick={() => {
                            this.setState({ show_more: true })
                        }} style={{ float: "left", marginRight: "2px" }}> <b style={{ float: "left", marginRight: "2px", color: 'black', marginRight: 2 }}>Enunciado: </b> <MdOutlineRule /> {'Ver Enunciado'} </p>
                        <ModalRedacao showModal={this.state.show_more} setShowModal={(e) => this.setState({ show_more: e })} prova_name={this.state.prova.name} regras={this.state.prova.enunciado} /> */}

                        {/* <div style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre',height:this.state.show_more==false?100:'fit-content',overflowY:this.state.show_more==false? 'hidden':'visible' }}>
                            <b style={{ float: "left", marginRight: "2px" }}>{'Regras:'} </b>
                            <br/>
                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.state.processo.regras }}>
                            </div>
                        </div> */}
                        {/* <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage: `url(${STORAGE_URL+ this.state.faculdade.logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat:'no-repeat',
                                backgroundPositionX:'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div> */}
                        <br />
                        <br />

                        <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Candidatos" />


                        </Tabs>

                        {<TabPanel value={this.state.activeTab} index={0}>
                            {<Candidatos entrevista={this.state.entrevista} processo={this.state.processo} />}



                        </TabPanel>}

                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowEntrevista);


