
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';


import { TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import PasswordInput from '../ReusableComponents/PasswordInput';
class EditarAvaliador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            email: '',
            password: '',
            role_id:'',
            password_confirmation: '',


            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: { name: '' },
        };


    }

    get_avaliador(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_avaliador/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, faculdade: resp.avaliador.faculdade,
                        name:resp.avaliador.name,
                        email:resp.avaliador.email,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('email', this.state.email);
        form.append('password', this.state.password);
        form.append('password_confirmation', this.state.password_confirmation);

        form.append('avaliador_id', this.props.match.params.id);




        fetch(`${URL}api/faculdade/update_avaliador`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false });
                    this.props.history.push({
                        pathname: `/instituicao/show/${this.state.faculdade.id}`,

                        search: '?' + new URLSearchParams({ tab: '3' }).toString()
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_avaliador(this.props.match.params.id);
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Avaliador</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um avaliador {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo'} {this.state.faculdade.name}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder="Nome do avaliador"
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>E-mail <Attention /></Form.Label>
                                            <Input
                                                placeholder="E-mail"
                                                value={this.state.email}
                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>

                                   
                                    <InputGroup>
                                        <PasswordInput
                                            label="Senha"
                                            placeholder="Digite sua senha"
                                            className="flex1"
                                            value={this.state.password}
                                            OnChangeText={(e) => this.setState({ password: e.target.value })}
                                        // title="Coloque a senha do usuário"
                                        />
                                    </InputGroup>

                                    <InputGroup>
                                        <PasswordInput
                                            label="Confirmação de senha"
                                            placeholder="Digite a confirmação de senha"
                                            className="flex1"
                                            value={this.state.password_confirmation}
                                            OnChangeText={(e) => this.setState({ password_confirmation: e.target.value })}
                                        // title="Coloque a senha do usuário"
                                        />
                                    </InputGroup>

                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            search={'?' + new URLSearchParams({ tab: 3 }).toString()}
                                            to={`/instituicao/show/${this.state.faculdade.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar avaliador"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando avaliador"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { logout })(EditarAvaliador);


