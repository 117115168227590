import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import { API_URL, URL } from "../../variables";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, FormControl as Input, InputGroup, Select, Spinner, FormCheck } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import Attention from "../ReusableComponents/Attention";

var pesquisarTimeout = null;

const Candidatos = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_zip, setLoadingZip] = useState(false);

    const [loading_entrevistas, setLoadingentrevistas] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');
    const [message_zip, setMessageZip] = useState('');


    const [message_zip2, setMessageZip2] = useState('');
    const [loading_zip2, setLoadingZip2] = useState(false);


    const [entrevistas, setentrevistas] = useState([]);

    const [questoes, setquestoes] = useState([]);
    const [candidatoSelect, setCandidatoSelect] = useState({});
    const [loading_save, setLoadingSave] = useState(false);
    const [msgErro, setMsgErro] = useState('');


    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });
    const { entrevista, processo } = props;

    const [inicio, setInicio] = useState(moment(processo.inicio));
    const [fim, setFim] = useState(moment(processo.inicio));
    const [hora_inicio, setHoraInicio] = useState(moment(processo.inicio));
    const [hora_fim, setHoraFim] = useState(moment(processo.inicio).add('hour', 1));



    const [search, setSearch] = useState('');
    const [show_warning, setShowWarning] = useState(false);
    const [show_modal_edit, setShowModalEdit] = useState(false);

    const [entrevistas_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [entrevistaSelect, setProvaSelect] = useState(null);


    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();
    const timerRef = useRef(null);
    const timerRefZip = useRef(null);


    const GetCandidatos = async (page, search) => {

        let response = await Request.Get(`faculdade/get_candidatos_entrevista/${entrevista.id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setentrevistas(response.candidatos);



            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingentrevistas(false);


        }
    }


    const SaveDatetime = async () => {
        setLoadingSave(true);
        let response = await Request.Post(`faculdade/save_date_time`, JSON.stringify({
            entrevista_id: entrevista.id,
            inicio: inicio.format('YYYY-MM-DD') + ' ' + hora_inicio.format('HH:mm'),
            fim: fim.format('YYYY-MM-DD') + ' ' + hora_fim.format('HH:mm'),
            student_id: candidatoSelect.id
        }), {
            'Content-type': 'application/json',
        });
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        else if (response.errors != null || response.error != null) {
            let errors = Object.values(response.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                else
                    erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);
            setLoadingSave(false);
            setMsgErro(erro)
        }
        else {
            setLoadingSave(false);
            setShowModalEdit(false)
            setLoadingentrevistas(true);
            GetCandidatos(pagination.current_page, search_oficial)

        }

    }


    const GetCandidatosPdfZip = async () => {

        let response = await Request.Get(`faculdade/download_diagramacoes/${entrevista.id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else {
            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip(false);
                setMessageZip('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip(response['message']);
            }
            timerRef.current = setTimeout(() => {
                GetCandidatosPdfZip();
            }, 20000);

        }


    }




    const GetRelatorios = async () => {

        let response = await Request.Get(`faculdade/get_respostas_entrevista/${entrevista.id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'entrevistas_processo_'+processo_name+'.zip');
        // }
        else {
            // setLoadingZip(false);
            // setMessageZip('');
            let blobo = new Blob([response['csv']])

            if (response['status'] == true) {
                saveAs(blobo,'resultados_'+entrevista.name+'.csv');
            }

            setLoadingZip(false);


        }


    }

    const DeleteRedacao = async (entrevistas_id) => {

        let response = await Request.Delete(`faculdade/desvincular_entrevista/${entrevistas_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        GetCandidatos(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        GetCandidatos(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
            clearTimeout(timerRefZip.current)

        }
    }, []);

    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">

                <a id="download_link" target="_blank"></a>

                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam os candidatos da entrevista "{entrevista.name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}
                    <a id="download_link" target="_blank"></a>
                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome/cpf/número de inscrição do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingentrevistas(true);

                                GetCandidatos(1, value)

                            }, 500);
                        }} />

                        <div className=" mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            <div className="d-flex " style={{
                                justifyContent: 'flex-end'
                            }}>

                                {loading_zip == false && <span onClick={() => {
                                    if (loading_zip == false) {
                                        setLoadingZip(true);
                                        setMessageZip('Criando relatório para download');
                                        GetRelatorios();
                                    }
                                    else {

                                    }
                                }} style={{ textDecoration: "none" }}>
                                    {<Button variant="success" className="flex align-center jcc"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar planilha com notas</p></Button>}

                                </span>}
                                {loading_zip == true && <div style={{ flexBasis: "50%", paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress size={15} />
                                </div>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                    </div>
                                </div>}

                            </div>


                        </div>


                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>CPF</th>
                                        <th>Número de Inscrição</th>
                                        <th>Data/Hora da avaliação</th>
                                        <th>Fez avaliação oral?</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_entrevistas == false && entrevistas.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.cpf}</td>
                                            <td>{item.numero_inscricao}</td>
                                            <td>{item.exams_oral == null ? '-' : (
                                                item.exams_oral.datetime_initial != null ?
                                                    (<>
                                                        <p style={{ marginBottom: 0 }}><b>Inicio:</b> {moment(item.exams_oral.datetime_initial).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p style={{ marginBottom: 0 }}><b>Fim:</b> {moment(item.exams_oral.datetime_end).format('DD/MM/YYYY HH:mm')}</p>
                                                    </>) : '-'
                                            )}</td>
                                            <td>{(item.exams_oral == null || item.exams_oral.finish == false) ? 'Não' : 'Sim'}</td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>


                                                    <span
                                                    onClick={() => {
                                                        setCandidatoSelect(item);
                                                       
                                                        setMsgErro('');
                                                        setShowModal(true);
                                                    }}
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title={"Ver avaliação do candidato " + item.name}
                                                    >
                                                        <BsFillEyeFill color="white" size={17} />
                                                    </span>
                                                {/* <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span> */}
                                                <span
                                                    onClick={() => {
                                                        setCandidatoSelect(item);
                                                        if (item.exams_oral?.datetime_initial != null) {
                                                            setInicio(moment(item.exams_oral.datetime_initial))
                                                            setHoraInicio(moment(item.exams_oral.datetime_initial))
                                                            setFim(moment(item.exams_oral.datetime_end))
                                                            setHoraFim(moment(item.exams_oral.datetime_end))
                                                        }
                                                        setMsgErro('');
                                                        setShowModalEdit(true);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                    data-toggle="tooltip"
                                                    title="Editar Data/hora da avaliação"
                                                >
                                                    <MdModeEdit color="white" size={17} />
                                                </span>
                                                {/* <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular entrevistas"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span> */}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_entrevistas == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_entrevistas == false && entrevistas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma entrevista encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingentrevistas(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            GetCandidatos(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_entrevistas={this.get_entrevistas.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingentrevistas(true);
                        DeleteRedacao(entrevistas_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este entrevista, ele não terá mais acesso a este processo
                </SweetAlert>

                <Modal
                    show={show_modal_edit == true}
                    dialogClassName="modal_width"
                    onHide={() => {
                        setShowModalEdit(false);
                    }}
                >
                    <Modal.Header closeButton closeLabel=''><Modal.Title>Candidato {candidatoSelect?.name}<br />
                    </Modal.Title></Modal.Header>
                    <Modal.Body>
                        {msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>


                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>

                            </div>
                            <br />

                        </div>}

                        <MuiPickersUtilsProvider utils={MomentUtils}>

                            <InputGroup>

                                <div className="flex fdcolumn flex1 margin-bottom me-3">
                                    <Form.Label>Data de início <Attention /></Form.Label>
                                    <DatePicker
                                        autoOk
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        invalidDateMessage="Data inválida"
                                        ampm={false}
                                        minDate={moment(processo.inicio)}
                                        maxData={moment(processo.fim)}
                                        id="time-picker"
                                        cancelLabel="Cancelar"
                                        clearLabel="Limpar"
                                        format="DD/MM/YYYY"
                                        value={inicio}
                                        onChange={(date) => setInicio(date)}

                                    />
                                </div>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Horário de início <Attention /></Form.Label>
                                    <TimePicker
                                        autoOk
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        invalidDateMessage="Horário inválido"
                                        ampm={false}
                                        id="time-picker"
                                        cancelLabel="Cancelar"
                                        clearLabel="Limpar"
                                        label=""
                                        value={hora_inicio}
                                        onChange={(date) => { setHoraInicio(date) }}

                                    />
                                </div>
                            </InputGroup>

                            <InputGroup>

                                <div className="flex fdcolumn flex1 margin-bottom me-3">
                                    <Form.Label>Data de encerramento <Attention /></Form.Label>
                                    <DatePicker
                                        autoOk
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        invalidDateMessage="Data inválida"
                                        ampm={false}
                                        minDate={moment(processo.inicio)}
                                        maxData={moment(processo.fim)}
                                        id="time-picker"
                                        cancelLabel="Cancelar"
                                        clearLabel="Limpar"
                                        format="DD/MM/YYYY"
                                        value={fim}
                                        onChange={(date) => { setFim(date) }}

                                    />
                                </div>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Horário de encerramento <Attention /></Form.Label>
                                    <TimePicker
                                        autoOk
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        invalidDateMessage="Horário inválido"
                                        ampm={false}
                                        id="time-picker"
                                        cancelLabel="Cancelar"
                                        clearLabel="Limpar"
                                        label=""
                                        value={hora_fim}
                                        onChange={(date) => { setHoraFim(date) }}

                                    />
                                </div>
                            </InputGroup>



                        </MuiPickersUtilsProvider>




                        {loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                            {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                            <DefaultButton
                                bg="secondary"
                                text="Fechar"
                                icon={<RiCloseFill />}
                                onClick={() => {
                                    setShowModalEdit(false);
                                }}
                            />
                            {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                            <DefaultButton
                                type="button"

                                onClick={() => {
                                    SaveDatetime();
                                }}
                                bg={"confirm"}
                                text="Salvar"
                            />
                        </div>}
                        {loading_save == true && <div className="w100 inline-flex justify-content-end margin-top-1">
                            <CircularProgress />
                        </div>}


                    </Modal.Body>
                </Modal>






                <Modal
                    show={showModal == true}
                    dialogClassName="modal_width"
                    onHide={() => {
                        setShowModal(false);
                    }}
                >
                    <Modal.Header closeButton closeLabel=''><Modal.Title>Candidato {candidatoSelect?.name}<br />
                    </Modal.Title></Modal.Header>
                    <Modal.Body>
                        <p style={{}}><b style={{ color: 'black', fontSize: 18 }}>Critérios:</b> </p>

                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Avaliador</th>
                                            <th>Código do Critério</th>
                                            <th>Texto do Critério</th>
                                            <th>Range</th>
                                            <th>Nota</th>
                                        </tr>

                                    </thead>
                                    <tbody >

                                        {candidatoSelect?.exams_oral?.grades?.map((item, id) => (
                                            <tr key={id}>
                                                <td>
                                                    {item.avaliador.name}
                                                </td>
                                                <td>
                                                    {item.criterio.code}

                                                </td>
                                                <td>
                                                    {item.criterio.text}

                                                </td>
                                                <td>
                                                    {item.criterio.min_score} - {item.criterio.max_score}
                                                </td>
                                                <td>
                                                    {item.grade}

                                                </td>
                                            </tr>
                                        ))}

                                        {candidatoSelect?.exams_oral?.grades?.length == 0 && <tr >
                                            <td colSpan="5">Nenhuma nota registrada</td>
                                        </tr>}



                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <p style={{ color: 'black',  marginBottom: 10 }}><b >Nota final:</b> {candidatoSelect?.exams_oral?.grades?.map((item)=>parseFloat(item.grade))?.reduce((accumulator, currentValue) => {
  return parseFloat(accumulator) + parseFloat(currentValue)
},0)}</p>




                        {loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                            {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                            <DefaultButton
                                bg="secondary"
                                text="Fechar"
                                icon={<RiCloseFill />}
                                onClick={() => {
                                    setShowModal(false);
                                }}
                            />
                            {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}

                        </div>}
                        {loading_save == true && <div className="w100 inline-flex justify-content-end margin-top-1">
                            <CircularProgress />
                        </div>}


                    </Modal.Body>
                </Modal>


            </div>

        </div>
    )
}

export default Candidatos;
