import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';



const AvaliadoresVinculados = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_avaliadores, setLoadingavaliadores] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [avaliadores, setavaliadores] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name,online } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [avaliadores_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');

    const [loading_zip2, setLoadingZip2] = useState(false);
    const [message_zip2, setMessageZip2] = useState('');
    const timerRef = useRef(null);

    const user = useSelector(state => state.AppReducer.user);



    const Getavaliadores = async (page, search) => {

        let response = await Request.Get(`faculdade/get_avaliadores_vinculados/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        console.log(search)
        console.log(search_oficial)
        console.log(page)


        if (response.pagination.current_page == page) {

            setavaliadores(response.avaliadores);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingavaliadores(false);


        }
    }

  

   

    const DeleteRedacao = async (avaliadores_id) => {

        let response = await Request.Delete(`faculdade/desvincular_avaliador/${avaliadores_id}/${processo_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getavaliadores(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getavaliadores(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
        }
    }, []);

    const GetPdfsZip = async () => {

        let response = await Request.Get(`faculdade/download_pdfs_images/`+processo_id);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else{
            if(response['status']==true){
                document.getElementById('download_link').href=response['path'];
                document.getElementById('download_link').click();
                setLoadingZip2(false);
            setMessageZip2('');

                return;
            }
            else if(response['status']!='Error'){
                setMessageZip2(response['message']);
            }
            timerRef.current = setTimeout(()=>{
                GetPdfsZip();
            },20000);

        }


    }

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">
            <a id="download_link" target="_blank"></a>


                {/* <hr /> */}
                {loading_screen == false && <div>
                    
                    <p className="text-muted">Aqui ficam os avaliadores vinculados ao processo "{processo_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome do avaliador" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            console.log('VALue:',value)
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                console.log(value)
                                setLoadingavaliadores(true);

                                Getavaliadores(1, value)

                            }, 500);
                        }} />





                        <div className=" mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>
                            

                            <div className="d-flex " style={{
                                justifyContent: 'flex-end'
                            }}>


                               

                                



                                <Link to={{
                                    pathname: "/avaliadores_vinculados/register/" + processo_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaPlus style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar avaliadores</p></Button>
                                </Link>
                            </div>
                           
                        </div>

                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_avaliadores == false && avaliadores.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                                {/* <Link to={`/avaliadores_vinculados/show/${item.id}/${processo_id}`} onClick={(e) => e.stopPropagation()}>

                                                    <span

                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar avaliador"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </Link> */}
                                                

                                                {item.deleted_at==null && <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular avaliadores"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_avaliadores == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_avaliadores == false && avaliadores.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum avaliador encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingavaliadores(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getavaliadores(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_avaliadores={this.get_avaliadores.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingavaliadores(true);
                        DeleteRedacao(avaliadores_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este avaliador, ele não terá mais acesso a este processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default AvaliadoresVinculados;
