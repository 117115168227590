import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";



const Entrevista = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_entrevistas, setLoadingentrevistas] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [entrevistas, setentrevistas] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name, inicio,simulado } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [entrevista_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getentrevistas = async (page, search) => {

        let response = await Request.Get(`faculdade/get_entrevistas/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setentrevistas(response.entrevistas);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingentrevistas(false);


        }
    }

    const DeleteRedacao = async (entrevista_id) => {

        let response = await Request.Delete(`faculdade/delete_entrevista/${entrevista_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getentrevistas(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getentrevistas(1, search_oficial);
    }, []);
    const user = useSelector(state=>state.AppReducer.user);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui ficam as entrevistas criadas {(user.empresa==null || user.empresa.change_text==false)?'do processo':'da vaga/cargo'} "{processo_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo nome da entrevista" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setLoadingentrevistas(true);

                                Getentrevistas(1, value)

                            }, 500);
                        }} />

                    {entrevistas.length==0 && <div style={{display:'flex'}}>
                        
                        <Link to={{
                            pathname: "/entrevistas/register/" + processo_id,
                            // state: {
                            //     course_id: id
                            // }
                        }} style={{ textDecoration: "none" }}>
                            <Button variant="success" className="flex align-center jcc"><FaPlus style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar entrevista</p></Button>
                        </Link>
                        </div>}
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>Critérios</th>
                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_entrevistas == false && entrevistas.map((item, id) => (

                                        <tr key={id}>
                                             <td>{item.name}</td>
                                             <td>{item.criterios.map((item,id)=>{
                                                return <p key={id}>- {item.code}: {item.text} - Nota de {item.min_score} a {item.max_score}</p>
                                             })}</td>
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}
                                            <td>
                                                <Link to={`/entrevistas/show/${item.id}`} onClick={(e) => e.stopPropagation()}>

                                                    <span
                                                        onClick={() => {

                                                        }}
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar entrevista"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </Link>
                                                { <Link to={`/entrevistas/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar entrevista"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>}
                                                {  <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar entrevista"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_entrevistas == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_entrevistas == false && entrevistas.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma entrevista encontrada</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingentrevistas(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getentrevistas(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_entrevista={this.get_entrevista.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingentrevistas(true);
                        DeleteRedacao(entrevista_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar esta entrevista, ele não estará mais disponível para os candidatos responde-lá
                </SweetAlert>


            </div>

        </div>
    )
}

export default Entrevista;
