
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';



import { TextField } from '@material-ui/core';
import './label.css';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';
import ModalCorretor from './ModalCorretor';
class CriarCorretorVinculado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value: '',
            corretor_enunciado: '',
            show_autocomplete_corretor: false,
            loading_search_corretor: false,
            corretores: [],
            corretores_selecionadas: {},
            salas:{},
            showModal: false,
            corretorSelect: null,
            redacoes:[]

        };


    }

    get_processo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_dados_corretor_vinculado/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let salas={};
                    for(let i=0;i<resp.salas.length;i++){
                        salas[resp.salas[i].id]=resp.salas[i];
                    }
                    this.setState({
                        loading_screen: false, processo: resp.processo,
                        salas,
                        redacoes:resp.redacoes
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let corretores = [...Object.values(this.state.corretores_selecionadas)];
        for(let i=0;i<corretores.length;i++){
            corretores[i]={...corretores[i]};
            let salas=[];
            for(let j=0;j<corretores[i].salas.length;j++){
                salas.push(corretores[i].salas[j].id+'_'+corretores[i].salas[j].redacao);
            }
            corretores[i].salas=salas;
            
        }
        // for (let i = 0; i < corretores.length; i++) {
        //     form.append('corretores[]', JSON.stringify(corretores[i]);
        // }
        // form.append('processo_id', this.state.processo.id);



        fetch(`${URL}api/faculdade/store_corretores_vinculados`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type':'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                corretores,
                processo_id:this.state.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,redirect:true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_processo(this.props.match.params.id);
    }

    get_corretor_search(search) {
        if (this.state.loading_search_corretor == false) {
            this.setState({ loading_search_corretor: true });
        }
        fetch(`${URL}api/faculdade/get_corretores_nao_vinculados_search/${this.state.processo.id}?page=1&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_corretor: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_corretor: false, corretores: resp.corretores });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_corretor: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_corretor: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    select_corretor(item,salas) {
        let corretores = { ...this.state.corretores_selecionadas };
        corretores[item.id] = item;
        this.setState({ corretor_enunciado: '',  corretores_selecionadas: corretores,salas })
    }


    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={`/processos/show/${this.props.match.params.id}?tab=5`} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card" style={{ overflow: 'visible' }}>

                            <div hidden id="text_enunciado">

                            </div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Vincular Corretor(es)</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Vincule um ou mais corretores ao processo {this.state.processo.name}</p>
                            <br />
                            {this.state.showModal==true && <ModalCorretor
                            redacoes={this.state.redacoes}
                            showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} corretor={this.state.corretorSelect} salas_all={Object.values(this.state.salas).sort((a, b) => a.created_at - b.created_at)} OnFinish={(salas,corretor)=>{
                                corretor.salas=salas;
                                // let salas_total={...this.state.salas};
                                // for(let i=0;i<salas.length;i++){
                                //     delete salas_total[salas[i].id];
                                // }
                                this.select_corretor(corretor,this.state.salas);
                                

                            }} />}

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>

                                    {/* <h5 style={{ fontWeight: 'bold' }}>Candidatos</h5>
                                    <br /> */}


                                    {<InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Busque um ou mais corretores que deseja vincular </Form.Label>
                                            <Input
                                                placeholder="Pesquise o corretor pelo nome"
                                                value={this.state.corretor_enunciado}
                                                // onChange={(e) => this.setState({ corretor_enunciado: e.target.value })}
                                                onBlur={() => {
                                                    this.timeout_ = setTimeout(() => {
                                                        this.setState({ show_autocomplete_corretor: false });

                                                    }, 500)
                                                }}
                                                onFocus={() => {
                                                    clearTimeout(this.timeout_);
                                                    this.setState({ show_autocomplete_corretor: true })
                                                }}
                                                onChange={(e) => { 
                                                    clearTimeout(this.search_timeout);
                                                    this.search_timeout=setTimeout(()=>{
                                                        this.get_corretor_search(e.target.value); 
                                                    },1000);
                                                    this.setState({ corretor_enunciado: e.target.value }) 
                                                }}
                                            // title="Coloque o nome completo do usuário"
                                            />
                                            {this.state.show_autocomplete_corretor == true && this.state.corretor_enunciado != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                                                {this.state.loading_search_corretor == false && this.state.corretores.map((item, id) => (
                                                    <div key={id} onClick={() => {
                                                        if(this.state.corretores_selecionadas[item.id]==undefined){
                                                            this.setState({corretorSelect:item,showModal:true,show_autocomplete_corretor: false,});
                                                        }
                                                        else{
                                                            this.setState({corretorSelect:this.state.corretores_selecionadas[item.id],showModal:true,show_autocomplete_corretor: false,});
                                                        }
                                                        // this.select_corretor(item);
                                                    }} className="opcao_autocomplete">
                                                        <b>{item.name}</b> - {item.email}
                                                    </div>
                                                ))}
                                                {this.state.loading_search_corretor == false && this.state.corretores.length == 0 &&
                                                    <div className="opcao_autocomplete">
                                                        Nenhum corretor encontrado com este termo
                                                    </div>
                                                }
                                                {this.state.loading_search_corretor == true &&
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div></div>}
                                            </div>}
                                        </div>
                                    </InputGroup>}



                                    <Form.Label >Corretores selecionados <Attention /></Form.Label>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>E-mail</th>
                                                                <th>Salas</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(this.state.corretores_selecionadas).map((item, id) => (
                                                                <tr key={id}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.salas.map((item2,id2)=><p style={{marginBottom:0}} key={id2}>Sala {item2.numero} - {item2.redacao_name}</p>) }</td>

                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                                                    <td>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ corretorSelect: item, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Gerenciar aula"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let salas_total={...this.state.salas};
                                                                                for(let i=0;i<item.salas.length;i++){
                                                                                    salas_total[item.salas[i].id]={...item.salas[i]};
                                                                                }
                                                                                let corretores_selecionadas = { ...this.state.corretores_selecionadas };
                                                                                delete corretores_selecionadas[item.id];
                                                                                this.setState({ corretores_selecionadas,salas:salas_total });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Desvincular corretor"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {Object.values(this.state.corretores_selecionadas).length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum corretor selecionado</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>


                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            search={'?tab=5'}
                                            to={`/processos/show/${this.props.match.params.id}`}
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar corretores"
                                            disabled={this.state.loading_image == true}
                                            loadingtext="Salvando corretores"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarCorretorVinculado);


