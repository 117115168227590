import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../css/modal.css';
import Attention from "../ReusableComponents/Attention";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const ModalCorretor = props => {
    const { processo_name, showModal, setShowModal, corretor, salas_all, OnFinish, redacoes } = props;
    const [salas_selecionadas, setSalasSelecionadas] = useState({});
    const [selectSala, setSelectSala] = useState('');
    const [salas_total, setSalasTotal] = useState({});


    useEffect(() => {
        if (corretor != null && corretor.salas != undefined) {
            let salas_selecionadas_ = {};
            for (let i = 0; i < corretor.salas.length; i++) {
                salas_selecionadas_[corretor.salas[i].id] = corretor.salas[i];
            }
            setSalasSelecionadas(salas_selecionadas_);
        }
    }, [corretor]);

    useEffect(() => {
        let salas_total_ = {};
        for (let i = 0; i < salas_all.length; i++) {
            salas_total_[salas_all[i].id] = salas_all[i];
        }
        setSalasTotal(salas_total_);

    }, []);

    console.log(salas_total);

    return (
        corretor != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => {
                setShowModal(false);
                setSalasSelecionadas({});
            }}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Corretor {corretor.name}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Salas <Attention /></Form.Label>
                        <select className="form-select " onChange={(e) => {
                            if (e.target.value != '') {
                                let salas_selecionadas_ = { ...salas_selecionadas };
                                let salas = { ...salas_total };
                                delete salas[e.target.value];
                                salas_selecionadas_[e.target.value] = { ...salas_total[e.target.value] };
                                salas_selecionadas_[e.target.value].redacao='all';
                                salas_selecionadas_[e.target.value].redacao_name='Todas';

                                setSalasSelecionadas(salas_selecionadas_);
                                setSalasTotal(salas);
                            }
                        }} value={selectSala}>
                            <option value="">Selecione uma ou mais salas</option>
                            {Object.values(salas_total).sort((a, b) => a.created_at - b.created_at).map((value, index) => (
                                <option
                                    key={index}
                                    value={value["id"]}
                                >Sala {value["numero"]} - Presentes: {value.presentes} - Total: {value.candidatos}</option>
                            ))}
                        </select>

                    </div>
                </InputGroup>

                <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sala</th>
                                            <th>Presentes</th>
                                            <th>Total</th>
                                            <th>Corrigirá quais Redações?</th>
                                            <th data-priority="3">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(salas_selecionadas).sort((a, b) => a.created_at - b.created_at).map((item, id) => (
                                            <tr key={id}>
                                                <td>Sala {item.numero}</td>
                                                <td>{item.presentes}</td>
                                                <td>{item.candidatos}</td>
                                                <td>
                                                    <select className="form-select " onChange={(e) => {
                                                        if (e.target.value != '') {
                                                            let salas_selecionadas_ = { ...salas_selecionadas };
                                                            console.log(e.target.value);
                                                            console.log({...item})
                                                            console.log({...salas_selecionadas_})
                                                            salas_selecionadas_[item.id].redacao = e.target.value;
                                                            salas_selecionadas_[item.id].redacao_name = e.target.value=='all'?'Todas': redacoes.filter((item2)=>item2.id==e.target.value)[0].name;

                                                            setSalasSelecionadas(salas_selecionadas_);
                                                        }
                                                    }} value={salas_selecionadas[item.id].redacao}>
                                                        <option value="all">Todas</option>
                                                        {redacoes.map((value, index) => (
                                                            <option
                                                                key={index}
                                                                value={value["id"]}
                                                            >{value['name']}</option>
                                                        ))}
                                                    </select>
                                                </td>

                                                <td>
                                                    <span
                                                        onClick={(e) => {
                                                            let salas = { ...salas_total };
                                                            salas[item.id] = { ...salas_selecionadas[item.id] };

                                                            let salas_selecionadas_ = { ...salas_selecionadas };
                                                            delete salas_selecionadas_[item.id];

                                                            setSalasSelecionadas(salas_selecionadas_);
                                                            setSalasTotal(salas);

                                                        }}
                                                        className="inline-flex align-center jcc bg-danger icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Deletar sala"
                                                    >
                                                        <MdDelete size={17} color="white" />
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {Object.values(salas_selecionadas).length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhuma sala selecionada</p>

                            </div>
                        </div>}
                    </div>

                </InputGroup>


                {<div className="w100 inline-flex jcsb margin-top-1">
                    {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                    <DefaultButton
                        bg="secondary"
                        text="Fechar"
                        icon={<RiCloseFill />}
                        onClick={() => {
                            setShowModal(false);
                            setSalasSelecionadas({});
                        }}
                    />
                    {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                    <DefaultButton
                        type="button"
                        disabled={Object.values(salas_selecionadas).length == 0}
                        onClick={() => {
                            OnFinish(Object.values({ ...salas_selecionadas }).sort((a, b) => a.created_at - b.created_at), corretor);
                            setShowModal(false);

                            setSalasSelecionadas({});


                        }}
                        bg={Object.values(salas_selecionadas).length > 0 ? "confirm" : "secondary"}
                        text="Salvar"
                    />
                </div>}

            </Modal.Body>
        </Modal>

    );
}

export default ModalCorretor;