import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import ModalCorretoresCsv from "./ModalCorretoresCsv";



const CorretoresVinculados = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_corretores, setLoadingcorretores] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [corretores, setcorretores] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [corretores_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const Getcorretores = async (page, search) => {

        let response = await Request.Get(`faculdade/get_corretores_vinculados/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {

            setcorretores(response.corretores);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingcorretores(false);


        }
    }

   


    const GetRelatórioCorretor = async (user) => {

        let response = await Request.Get(`faculdade/get_correcoes_corretor/${processo_id}/${user.id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        document.getElementById('download_link').href=response['link'];
                document.getElementById('download_link').click();
        // var csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response.csv);

        // saveAs(csvContent,'correcoes_corretor_'+user.name+'.csv');

    }

    const DeleteRedacao = async (corretores_id) => {

        let response = await Request.Delete(`faculdade/desvincular_corretor/${corretores_id}/${processo_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getcorretores(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getcorretores(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">
            <a id="download_link" target="_blank"></a>


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <ModalCorretoresCsv onFinish={() => {
                        setLoadingcorretores(true);
                        Getcorretores(1, search_oficial);
                    }} showModal={showModal} setShowModal={(e) => setShowModal(e)} processo_id={processo_id} processo_name={processo_name} />

                    <p className="text-muted">Aqui ficam os corretores vinculados ao processo "{processo_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-2">

                        <Search placeholder="Pesquisar pelo nome do corretor" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setSearchOficial(value);
                                setLoadingcorretores(true);

                                Getcorretores(1, value)

                            }, 500);
                        }} />


                        <Link to={{
                            pathname: "/corretores_vinculados/register/" + processo_id,
                            // state: {
                            //     course_id: id
                            // }
                        }} style={{ textDecoration: "none" }}>
                            <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar corretores</p></Button>
                        </Link>
                    </div>
                    <div className="mb-2 d-flex justify-content-end">
                    <Button onClick={() => {
                                setShowModal(true);
                            }} variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar corretores via .csv</p></Button>

                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Salas</th>
                                        <th>Correções Redações</th>

                                        <th>Alunos</th>
                                        <th>Correções Dissertativas</th>


                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_corretores == false && corretores.map((item, id) =>(

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.salas.length? item.salas.map((item2,id2)=><p style={{marginBottom:0}} key={id2}>Sala {item2.sala.numero} - Redação: {item2.redacao.name}</p>):'-' }</td>
                                            <td>{item.count} de {item.total}</td>

                                            <td>{item.numero_alunos }</td>
                                            <td>{item.respostas_alunos_corrigidas} de {item.respostas_alunos}</td>


                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                                <span
                                                    onClick={() => {
                                                        GetRelatórioCorretor(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar correções"
                                                >
                                                    <MdFileDownload size={17} color="white" />
                                                </span>
                                               
                                                {/* <Link to={`/corretores_vinculados/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-info icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link> */}
                                                <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular corretores"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_corretores == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_corretores == false && corretores.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum corretor encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingcorretores(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getcorretores(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_corretores={this.get_corretores.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingcorretores(true);
                        DeleteRedacao(corretores_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este corretor, ele não terá mais acesso as salas deste processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default CorretoresVinculados;
