
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// unidades
import moment from 'moment';
import { URL } from '../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { Pagination } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { FaListAlt } from 'react-icons/fa';
import Search from '../ReusableComponents/Search';
import { BsFillEyeFill } from "react-icons/bs";
import { Button } from "react-bootstrap";

class ModulosSistema extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            categorias: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            msgErro: '',
            show_warning: false,
            show_erro: false,
            unidade_to_delete: '',
            search: '',
            loading_search: true
        };
    }






    componentDidMount() {

        this.get_categorias(this.state.pagination.page,'',false);
    }

    get_categorias(page, search = '', busca = true) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_categorias?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({ loading_search: false, categorias: resp.categorias, loading_screen: false, pagination: resp.pagination });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_categoria(id) {

        fetch(`${URL}api/faculdade/delete_categoria/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });
                }
                else {
                    this.setState({ show_warning: false });
                    this.get_categorias(this.state.pagination.page, this.state.search);
                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
            });
    }







    render() {
        console.log(this.props.user?.empresa)
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Módulos do sistema</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os módulos do sistema e seus respectivos status</p>
                            <br />
                            <br />
                            <p><b>Tipos de status:</b></p>
                            <p style={{color:'green'}}>Ativo: Módulo operando em sua função normal</p>
                            <p style={{color:'darkgoldenrod'}}>Em manutenção: Módulo está passando por alguma atualização, podendo sofrer instabilidade</p>
                            <p style={{color:'red'}}>Inativo: Módulo está desligado</p>

                            <div className="row">

                                <div className="col-12">


                                    {/* <hr /> */}
                                    {this.state.loading_screen == false && <div>

                                        {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                                        {/* <br /> */}

                                       

                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Módulo</th>
                                                            <th>Descrição</th>
                                                            {<th>Status</th>}


                                                            <th data-priority="3">Atualizado em</th>
                                                            <th data-priority="3">Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loading_search == false && <>
                                                        <tr>
                                                            <td>Administrador</td>
                                                            <td>Módulo administrativo utilizado para fazer todos os cadastros no sistema e download dos resultados/relatórios</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >15/07/2024 03:00:00</td>
                                                            <td ><a target='_blank' href="https://admin.ontest.com.br">Acessar</a></td>

                                                        </tr>
                                                        <tr>
                                                            <td>Corretor</td>
                                                            <td>Módulo para correção de questões dissertativas e redações</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >01/07/2024 02:30:00</td>
                                                            <td ><a target='_blank' href="https://corretor.ontest.com.br">Acessar</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avaliador</td>
                                                            <td>Módulo para aplicação de prova oral, onde os avaliadores conseguem entrar em chamada com os candidatos</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >15/07/2024 03:00:00</td>
                                                            <td ><a target='_blank' href="https://avaliador.ontest.com.br">Acessar</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Elaborador</td>
                                                            <td>Módulo para cadastros de questões por parte de professores/elaboradoes, responder recursos e atender demandas</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >01/07/2024 02:30:00</td>
                                                            <td ><a target='_blank' href="https://elaborador.ontest.com.br">Acessar</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fiscal</td>
                                                            <td>Módulo de fiscalização/proctoring do candidato durante todo o perído da avaliação online</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >15/07/2024 03:00:00</td>
                                                            <td ><a target='_blank' href="https://fiscal.ontest.com.br">Acessar</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Candidato</td>
                                                            <td>Módulo para acesso do candidato</td>
                                                            <td style={{color:'green'}}>Ativo</td>
                                                            <td >15/07/2024 03:00:00</td>
                                                            <td ><a target='_blank' href="https://candidato.ontest.com.br">Acessar</a></td>
                                                        </tr>
                                                        </>}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        

                                        {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                                    </div>}

                                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        {/* <div className="spinner-border text-primary" role="status"  >
        </div> */}
                                        <CircularProgress />
                                    </div></div>}

                                    


                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ModulosSistema);


