




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdCheck, MdContentCopy, MdCopyAll, MdOutlineRule, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import TabPanel from "../ReusableComponents/TabPanel";
import TextReadMore from '../ReusableComponents/TextReadMore';
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import Processo from '../Processo/Processo';
import Corretores from '../Corretores/Corretores';
import Fiscais from '../Fiscais/Fiscais';
import Alunos from '../Alunos/Alunos';
import ModalTermos from './ModalTermos';
import './label.css';
import CandidatosConflitos from '../CandidatosConflitos/CandidatosConflitos';
import RelatoriosGerais from '../RelatoriosGerais/RelatoriosGerais';
import Avaliadores from '../Avaliador/Avaliadores';

class ShowFaculdade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            faculdade: {},
            activeTab: 0,
            show_more: false,
            copy_api_key: false,
            copy_secret_key: false
        };

    }


    get_faculdade(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_faculdade/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, faculdade: resp.faculdade
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.get_faculdade(this.props.match.params.id);
    }







    render() {
        if (this.state.loading_screen) return <SkeletonCoursePage />
        else return (
            <div style={{ position: "relative" }}>
                <div className="card">
                    <div className="card-body">
                        <div style={{ float: 'right' }}>
                            <DefaultButton
                                bg="secondary"
                                text="Voltar"
                                icon={<RiArrowGoBackLine />}
                                to={`/`}
                            />
                        </div>
                        <div className="flex jcsb">
                            <h4 className="header">{this.state.faculdade.name}</h4>
                        </div>


                        {/* <TextReadMore title="Responsável: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10 }}>
                            {responsavel}
                        </TextReadMore>
                        <TextReadMore title="Descrição: " element="div" limit={120} style={{ width: "100%" }}>
                            {course_description}
                        </TextReadMore> */}

                        <br />
                        <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div className="course-banner" style={{
                                backgroundImage: `url(${this.state.faculdade.logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: 'center',
                                width: "100%",
                                height: "200px",
                                borderRadius: "7px"
                            }} />
                        </div>
                        {this.props.user?.empresa?.online_proccess==true  && <>
                        <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div style={{borderRadius:10,border:'0.5px solid lightgrey',padding:10}}>
                                <p style={{marginBottom:0,fontWeight:'bold'}}>Api-key: <span id='api_key' style={{fontWeight:'normal'}}>{this.state.faculdade.apikey}</span>{this.state.copy_api_key==false  && <MdContentCopy onClick={()=>{
                                    navigator.clipboard.writeText(document.getElementById('api_key').textContent);
                                    this.setState({copy_api_key:true});
                                }} className="copy" />}{this.state.copy_api_key==true  && <MdCheck style={{color:'#4BB543'}} onClick={()=>{
                                    navigator.clipboard.writeText(document.getElementById('api_key').textContent);
                                }} className="copy" />}</p>
                            </div>
                        </div>
                        <div className="w100 flex align-center fdcolumn margin-bottom">
                            <div style={{borderRadius:10,border:'0.5px solid lightgrey',padding:10}}>
                                <p style={{marginBottom:0,fontWeight:'bold'}}>Api-secret: <span id='api_secret' style={{fontWeight:'normal'}}>{this.state.faculdade.apisecret}</span>{this.state.copy_secret_key==false  && <MdContentCopy onClick={()=>{
                                    navigator.clipboard.writeText(document.getElementById('api_secret').textContent);
                                    this.setState({copy_secret_key:true});
                                }} className="copy" />}{this.state.copy_secret_key==true  && <MdCheck style={{color:'#4BB543'}} onClick={()=>{
                                    navigator.clipboard.writeText(document.getElementById('api_secret').textContent);
                                }} className="copy" />}</p>
                            </div>
                        </div>

                        <br/>
                        <div className="w100 margin-bottom">

                        {(this.props.user.role=='region_admin' || (this.props.user.empresa==null || this.props.user.empresa.hide_criptografia==false)) && <TextReadMore title="Criptografia: " element="div" limit={120} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                {this.state.faculdade.criptografia}
                            </TextReadMore>}
                            <p className="ver_regras" onClick={() => {
                                this.setState({ show_more: true })
                            }} style={{ float: "left", marginRight: "2px" }}> <b style={{ float: "left", marginRight: "2px", color: 'black', marginRight: 2 }}> {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'Termos da Instituição':'Termos do Processo'} : </b> <MdOutlineRule /> {'Ver termos'} </p>
                            <ModalTermos showModal={this.state.show_more} setShowModal={(e) => this.setState({ show_more: e })} processo_name={this.state.faculdade.name} termos={this.state.faculdade.termos} />
                        </div>
                        <br />
                        <br />
                        </>}



                        <Tabs
                            value={this.state.activeTab}
                            onChange={(_, value) => {
                                this.setState({ activeTab: value });
                                this.props.history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label={(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?"Processos Seletivos":'Vagas/Cargos'} />
                            <Tab label="Corretores" />
                            <Tab label="Fiscais" />
                            <Tab label="Avaliadores" />
                            <Tab label="Candidatos" />
                            {this.props.user?.empresa?.online_proccess==true && <Tab label="Relatórios Gerais" />}

                            {/* <Tab label="Candidatos Conflitos" /> */}

                        </Tabs>
                        <TabPanel value={this.state.activeTab} index={0}>
                            <Processo faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug}/>
                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={1}>
                            <Corretores faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug}/>

                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={2}>
                            {/* <AddToCourse/> */}
                            {(this.props.user?.empresa?.online_proccess==true ) && <Fiscais faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug} />}
                            {(this.props.user?.empresa?.online_proccess==false ) && <p style={{textAlign:'center'}}>Módulo não habilitado</p>}

                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={3}>
                            {/* <AddToCourse/> */}
                            {(this.props.user?.empresa?.online_proccess==true ) && <Avaliadores faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug} />}
                            {(this.props.user?.empresa?.online_proccess==false ) && <p style={{textAlign:'center'}}>Módulo não habilitado</p>}

                        </TabPanel>
                        <TabPanel value={this.state.activeTab} index={4}>
                            <Alunos faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug}/>

                        </TabPanel>

                        {this.props.user?.empresa?.online_proccess==true && <TabPanel value={this.state.activeTab} index={5}>
                            <RelatoriosGerais faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug}/>

                        </TabPanel>}

                        {/* <TabPanel value={this.state.activeTab} index={4}>
                            <CandidatosConflitos faculdade_id={this.state.faculdade.id} faculdade_name={this.state.faculdade.name} slug={this.state.faculdade.slug}/>

                        </TabPanel> */}



                    </div>
                </div>

            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                {/* <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/> */}
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowFaculdade);


