import React, { useEffect, useState } from 'react';
import { URL } from '../variables';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import Aside from '../SideBar/Aside';

import '../SideBar/styles/App.scss';
import { mudarDados } from '../actions/AppActions';
import { Route, Switch } from 'react-router';

import Request from '../Request';
import Faculdades from './Faculdades/Faculdades';
import CriarFaculdade from './Faculdades/CriarFaculdade';
import EditarFaculdade from './Faculdades/EditarFaculdade';
import ShowFaculdade from './Faculdades/ShowFaculdade';
import CriarProcesso from './Processo/CriarProcesso';
import EditarProcesso from './Processo/EditarProcesso';
import CriarCorretor from './Corretores/CriarCorretor';
import EditarCorretor from './Corretores/EditarCorretor';
import CriarFiscal from './Fiscais/CriarFiscal';
import EditarFiscal from './Fiscais/EditarFiscal';
import CriarAluno from './Alunos/CriarAluno';
import EditarAluno from './Alunos/EditarAluno';
import CategoriasQuestoes from './CategoriasQuestoes/CategoriasQuestoes';
import CriarCategoria from './CategoriasQuestoes/CriarCategoria';
import EditarCategoria from './CategoriasQuestoes/EditarCategoria';
import Questoes from './CategoriasQuestoes/Questoes';
import CriarQuestao from './CategoriasQuestoes/CriarQuestao';
import EditarQuestao from './CategoriasQuestoes/EditarQuestao';
import ShowProcesso from './Processo/ShowProcesso';
import CriarRedacao from './Redacoes/CriarRedacao';
import EditarRedacao from './Redacoes/EditarRedacao';
import CriarProva from './Provas/CriarProva';
import EditarProva from './Provas/EditarProva';
import CriarCandidatoVinculado from './CandidatosVinculados/CriarCandidatoVinculado';
import CriarFiscalVinculado from './FiscaisVinculados/CriarFiscalVinculado';
import CriarCorretorVinculado from './CorretoresVinculados/CriarCorretorVinculado';
import ShowSala from './SalasProcesso/ShowSala';
import ShowRedacoesProcesso from './RedacoesProcesso/ShowRedacoesProcesso';
import ShowProvasProcesso from './ProvasProcesso/ShowProvasProcesso';
import ShowCandidato from './CandidatosVinculados/ShowCandidato';
import EditarCandidatoConflito from './CandidatosConflitos/EditarCandidatoConflito';
import ShowAnalise from './AnalisesCandidatos/ShowAnalise';
import CriarProvaExistente from './Provas/CriarProvaExistente';

import DashboardInstituicao from './Faculdades/Dashboard';
import Questionarios from './Questionarios/Questionarios';
import CriarQuestionario from './Questionarios/CriarQuestionario';
import EditarQuestionario from './Questionarios/EditarQuestionario';
import CriarQuestionarioVinculado from './QuestionariosVinculados/CriarQuestionarioVinculado';
import ShowQuestionarioProcesso from './QuestionariosVinculados/ShowQuestionarioProcesso';
import Professores from './Professores/Professores';
import CriarProfessor from './Professores/CriarProfessor';
import EditarProfessor from './Professores/EditarProfessor';
import Demandas from './Demandas/Demandas';
import CriarDemanda from './Demandas/CriarDemanda';
import EditarDemanda from './Demandas/EditarDemanda';
import ShowDemanda from './Demandas/ShowDemanda';
import CriarAtribuicoes from './Demandas/CriarAtribuicoes';
import Recursos from './Recursos/Recursos';
import CriarRecurso from './Recursos/CriarRecurso';
import EditarRecurso from './Recursos/EditarRecurso';
import ShowRecurso from './Recursos/ShowRecurso';
import QuestoesDemanda from './Demandas/QuestoesDemanda';
import CriarDiagramacao from './Diagramacoes/CriarDiagramacao';
import EditarDiagramacao from './Diagramacoes/EditarDiagramacao';
import Clientes from './Clientes/Clientes';
import CriarCliente from './Clientes/CriarCliente';
import EditarCliente from './Clientes/EditarCliente';
import EditProfile from './EditProfile/EditProfile';
import ShowAnaliseObjeto from './AnalisesCandidatosObjetos/ShowAnaliseObjeto';
import CriarOrganizadora from './Organizadoras/CriarOrganizadora';


import Organizadoras from './Organizadoras/Organizadoras';
import EditarOrganizadora from './Organizadoras/EditarOrganizadora';
import Membros from './Membros/Membros';
import CriarMembro from './Membros/CriarMembro';
import EditarMembro from './Membros/EditarMembro';
import CriarAtribuicoesRedacao from './Demandas/CriarAtribuicoesRedacao';
import ShowDiagramacao from './Diagramacoes/ShowDiagramacao';
import CriarAvaliador from './Avaliador/CriarAvaliador';
import EditarAvaliador from './Avaliador/EditarAvaliador';
import CriarAvaliadorVinculado from './AvaliadoresVinculados/CriarAvaliadorVinculado';
import CriarEntrevista from './Entrevista/CriarEntrevista';
import EditarEntrevista from './Entrevista/EditarEntrevista';
import ShowEntrevista from './Entrevista/ShowEntrevista';
import ModulosSistema from './ModulosSistema/ModulosSistema';



const Home = (props) => {
    const toggled = useSelector(store => store.AppReducer.toggled)
    const collapsed = useSelector(store => store.AppReducer.collapsed)
    const user = useSelector(store => store.AppReducer.user);
    const token = useSelector(store => store.AppReducer.token);
    const adminAsUser = useSelector(store => store.AppReducer.adminAsUser);

    const dispatch = useDispatch();
    const [loading_home, setLoadingHome] = useState(false);
    const [image, setImage] = useState(true);
    // const [toggled, setToggled] = useState(false);
    const handleCollapsedChange = (checked) => {
        dispatch(mudarDados({ collapsed: checked }));

    };

    const GetRole = async () => {
        let response = await Request.Post(`faculdade/auth/get_user`)
        if (!response) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            dispatch({type:'logout',payload:{}});
        }
        else if (response.message == "Unauthenticated.") {
            localStorage.removeItem('token'); localStorage.removeItem('user');
            dispatch({type:'logout',payload:{}});
            return;
        }
        else{
            dispatch({type:'login',payload:{token:token,user:response['user']}});
           
        }
        
    }

    const handleImageChange = (checked) => {
        setImage(checked);
    };

    const handleToggleSidebar = (value) => {
        // setToggled(value);
        dispatch(mudarDados({ toggled: value }));
    };

    

    useEffect(() => {
        const event = (e) => {
            if (window.innerWidth <= 768) {
                dispatch(mudarDados({ toggled: false, collapsed: false }));

            }
            else {

                dispatch(mudarDados({ toggled: true, collapsed: true }));

            }
        };
        window.addEventListener('resize', event)
        if (window.innerWidth <= 768) {
            dispatch(mudarDados({ toggled: false, collapsed: false }));
        }
        else {
            dispatch(mudarDados({ toggled: true, collapsed: true }));
        }
        GetRole();

        return () => {
            window.removeEventListener('resize', event);

            // Anything in here is fired on component unmount.
        }
    }, [])

    const marginLeft = (toggled==false || window.innerWidth<=768)?0:(collapsed==false?270:80);

    return (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Aside
                image={image}
                collapsed={collapsed}
                toggled={toggled}

                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}

            />
            <Header
                image={image}
                toggled={toggled}
                collapsed={collapsed}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
                handleImageChange={handleImageChange}
            />
            <div className="content-page" >
                <div className="content" style={{marginLeft: marginLeft,transition: 'all 0.3s ',paddingBottom:100,background:'#f8f8fa'}}>
                    <div className="container-fluid">
                        <Switch>

                            <Route exact path={`${props.match.url}`} render={(props2) => <Faculdades {...props2} />} />
                            <Route exact path={`${props.match.url}instituicao/register`} render={(props2) => <CriarFaculdade {...props2} />} />
                            <Route exact path={`${props.match.url}instituicao/dashboard/:faculdade_id`} render={(props2) => <DashboardInstituicao {...props2} />} />

                            <Route exact path={`${props.match.url}instituicao/edit/:id`} render={(props2) => <EditarFaculdade {...props2} />} />
                            <Route exact path={`${props.match.url}instituicao/show/:id`} render={(props2) => <ShowFaculdade {...props2} />} />

                            <Route exact path={`${props.match.url}processos/register/:id`} render={(props2) => <CriarProcesso {...props2} />} />

                            <Route exact path={`${props.match.url}processos/edit/:id`} render={(props2) => <EditarProcesso {...props2} />} />
                            
                            <Route exact path={`${props.match.url}processos/show/:id`} render={(props2) => <ShowProcesso {...props2} />} />

                            <Route exact path={`${props.match.url}corretores/register/:id`} render={(props2) => <CriarCorretor {...props2} />} />
                            <Route exact path={`${props.match.url}corretores/edit/:id`} render={(props2) => <EditarCorretor {...props2} />} />

                            <Route exact path={`${props.match.url}fiscais/register/:id`} render={(props2) => <CriarFiscal {...props2} />} />
                            <Route exact path={`${props.match.url}fiscais/edit/:id`} render={(props2) => <EditarFiscal {...props2} />} />

                            <Route exact path={`${props.match.url}avaliadores/register/:id`} render={(props2) => <CriarAvaliador {...props2} />} />
                            <Route exact path={`${props.match.url}avaliadores/edit/:id`} render={(props2) => <EditarAvaliador {...props2} />} />

                            <Route exact path={`${props.match.url}candidatos/register/:id`} render={(props2) => <CriarAluno {...props2} />} />
                            <Route exact path={`${props.match.url}candidatos/edit/:id`} render={(props2) => <EditarAluno {...props2} />} />

                            {(user.role=='region_admin' || (user.empresa==null || user.empresa.hide_questionario==false)) && <Route exact path={`${props.match.url}questionarios`} render={(props2) => <Questionarios {...props2} />} />}
                            {(user.role=='region_admin' || (user.empresa==null || user.empresa.hide_questionario==false)) && <Route exact path={`${props.match.url}questionarios/register`} render={(props2) => <CriarQuestionario {...props2} />} />}
                            {(user.role=='region_admin' || (user.empresa==null || user.empresa.hide_questionario==false)) && <Route exact path={`${props.match.url}questionarios/edit/:id`} render={(props2) => <EditarQuestionario {...props2} />} />}

                            <Route exact path={`${props.match.url}elaboradores`} render={(props2) => <Professores {...props2} />} />
                            <Route exact path={`${props.match.url}elaboradores/register`} render={(props2) => <CriarProfessor {...props2} />} />
                            <Route exact path={`${props.match.url}elaboradores/edit/:id`} render={(props2) => <EditarProfessor {...props2} />} />

                            <Route exact path={`${props.match.url}edit_profile`} render={(props2) => <EditProfile {...props2} />} />

                            {user?.empresa?.owner_id==user?.id &&  <Route exact path={`${props.match.url}membros/`} render={(props2) => <Membros {...props2} />} />}
                            {user?.empresa?.owner_id==user?.id &&  <Route exact path={`${props.match.url}membros/register/`} render={(props2) => <CriarMembro {...props2} />} />}
                            { user?.empresa?.owner_id==user?.id && <Route exact path={`${props.match.url}membros/edit/:id`} render={(props2) => <EditarMembro {...props2} />} />}


                            {user.role=='region_admin' && <Route exact path={`${props.match.url}organizadoras`} render={(props2) => <Organizadoras {...props2} />} />}
                            {user.role=='region_admin' && <Route exact path={`${props.match.url}organizadoras/register`} render={(props2) => <CriarOrganizadora {...props2} />} />}
                            {user.role=='region_admin' && <Route exact path={`${props.match.url}organizadoras/edit/:id`} render={(props2) => <EditarOrganizadora {...props2} />} />}


                            {user.role=='region_admin' && <Route exact path={`${props.match.url}organizadoras/membros/:id`} render={(props2) => <Clientes {...props2} />} />}
                            {user.role=='region_admin' &&  <Route exact path={`${props.match.url}organizadoras/membros/register/:id`} render={(props2) => <CriarCliente {...props2} />} />}
                            {user.role=='region_admin' && <Route exact path={`${props.match.url}organizadoras/membros/edit/:id`} render={(props2) => <EditarCliente {...props2} />} />}


                            <Route exact path={`${props.match.url}demandas`} render={(props2) => <Demandas {...props2} />} />
                            <Route exact path={`${props.match.url}demandas/register`} render={(props2) => <CriarDemanda {...props2} />} />
                            <Route exact path={`${props.match.url}demandas/edit/:id`} render={(props2) => <EditarDemanda {...props2} />} />
                            <Route exact path={`${props.match.url}demandas/show/:id`} render={(props2) => <ShowDemanda {...props2} />} />
                            <Route exact path={`${props.match.url}atribuicoes/register/:id`} render={(props2) => <CriarAtribuicoes {...props2} />} />
                            <Route exact path={`${props.match.url}atribuicoes/register_redacoes/:id`} render={(props2) => <CriarAtribuicoesRedacao {...props2} />} />

                            <Route exact path={`${props.match.url}atribuicoes/show/:demanda_id`} render={(props2) => <QuestoesDemanda {...props2} />} />


                            <Route exact path={`${props.match.url}recursos`} render={(props2) => <Recursos {...props2} />} />
                            <Route exact path={`${props.match.url}recursos/register`} render={(props2) => <CriarRecurso {...props2} />} />
                            <Route exact path={`${props.match.url}recursos/edit/:id`} render={(props2) => <EditarRecurso {...props2} />} />
                            <Route exact path={`${props.match.url}recursos/show/:id`} render={(props2) => <ShowRecurso {...props2} />} />



                            <Route exact path={`${props.match.url}disciplinas`} render={(props2) => <CategoriasQuestoes {...props2} />} />
                            <Route exact path={`${props.match.url}disciplinas/register`} render={(props2) => <CriarCategoria {...props2} />} />
                            <Route exact path={`${props.match.url}disciplinas/edit/:id`} render={(props2) => <EditarCategoria {...props2} />} />

                            <Route exact path={`${props.match.url}disciplinas/show/:id`} render={(props2) => <Questoes {...props2} />} />
                            <Route exact path={`${props.match.url}questoes/register/:id`} render={(props2) => <CriarQuestao {...props2} />} />
                            <Route exact path={`${props.match.url}questoes/edit/:id`} render={(props2) => <EditarQuestao {...props2} />} />


                            <Route exact path={`${props.match.url}redacoes/register/:id`} render={(props2) => <CriarRedacao {...props2} />} />
                            <Route exact path={`${props.match.url}redacoes/edit/:id`} render={(props2) => <EditarRedacao {...props2} />} />
                            <Route exact path={`${props.match.url}provas/register/:id`} render={(props2) => <CriarProva {...props2} />} />
                            <Route exact path={`${props.match.url}provas/register_existing/:id`} render={(props2) => <CriarProvaExistente {...props2} />} />
                            

                            <Route exact path={`${props.match.url}provas/edit/:id`} render={(props2) => <EditarProva {...props2} />} />

                            <Route exact path={`${props.match.url}entrevistas/register/:id`} render={(props2) => <CriarEntrevista {...props2} />} />
                            <Route exact path={`${props.match.url}entrevistas/edit/:id`} render={(props2) => <EditarEntrevista {...props2} />} />
                            <Route exact path={`${props.match.url}entrevistas/show/:id`} render={(props2) => <ShowEntrevista {...props2} />} />



                            <Route exact path={`${props.match.url}diagramacoes/register/:id`} render={(props2) => <CriarDiagramacao {...props2} />} />
                            <Route exact path={`${props.match.url}diagramacoes/edit/:id`} render={(props2) => <EditarDiagramacao {...props2} />} />

                            <Route exact path={`${props.match.url}questionarios_vinculados/register/:id`} render={(props2) => <CriarQuestionarioVinculado {...props2} />} />
                            <Route exact path={`${props.match.url}questionarios_vinculados/show/:processo_id/:questionario_id`} render={(props2) => <ShowQuestionarioProcesso {...props2} />} />

                            <Route exact path={`${props.match.url}avaliadores_vinculados/register/:id`} render={(props2) => <CriarAvaliadorVinculado {...props2} />} />

                            <Route exact path={`${props.match.url}candidatos_vinculados/show/:id/:processo_id`} render={(props2) => <ShowCandidato {...props2} />} />

                            <Route exact path={`${props.match.url}candidatos_vinculados/register/:id`} render={(props2) => <CriarCandidatoVinculado {...props2} />} />

                            <Route exact path={`${props.match.url}fiscais_vinculados/register/:id`} render={(props2) => <CriarFiscalVinculado {...props2} />} />
                            <Route exact path={`${props.match.url}corretores_vinculados/register/:id`} render={(props2) => <CriarCorretorVinculado {...props2} />} />
                            
                            <Route exact path={`${props.match.url}salas/show/:id`} render={(props2) => <ShowSala {...props2} />} />
                            <Route exact path={`${props.match.url}redacoes/show/:id`} render={(props2) => <ShowRedacoesProcesso {...props2} />} />


                            <Route exact path={`${props.match.url}provas/show/:id`} render={(props2) => <ShowProvasProcesso {...props2} />} />
                            <Route exact path={`${props.match.url}diagramacoes/show/:id`} render={(props2) => <ShowDiagramacao {...props2} />} />

                            <Route exact path={`${props.match.url}system`} render={(props2) => <ModulosSistema {...props2} />} />


                            <Route exact path={`${props.match.url}candidatos_errors/edit/:id`} render={(props2) => <EditarCandidatoConflito {...props2} />} />


                            <Route exact path={`${props.match.url}processos/show_analises/:id`} render={(props2) => <ShowAnalise {...props2} />} />
                            <Route exact path={`${props.match.url}processos/show_analises_objetos/:id/:processo_id`} render={(props2) => <ShowAnaliseObjeto {...props2} />} />


                        </Switch>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;