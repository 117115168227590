
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdFileDownload, MdReply } from 'react-icons/md';
import { Form, FormCheck, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from '../CategoriasQuestoes/ModalQuestao';



import { TextField } from '@material-ui/core';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { alpha } from '@material-ui/core/styles'
import { BsFile, BsFillEyeFill } from 'react-icons/bs';
import './style.css'
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';

import html2pdf from 'html2pdf.js';
import { uuid } from 'uuidv4';
import styled, { createGlobalStyle } from 'styled-components';
import { GiNunFace } from 'react-icons/gi';
import QRCode from "react-qr-code";
import { Rnd } from "react-rnd";

// import { Preview, print } from 'react-html2pdf';

// const StyleQuestion = styled.p` 
//   letter-spacing: 0.2px;
//   margin-bottom:0;
// `;

const StyleQuestion = createGlobalStyle`
 p {
letter-spacing: 0.2px;
margin-bottom: 0px;
 }
 hr {
  opacity:1;
  margin-top:10px;
  margin-bottom:10px;
 }
`

function getCaretCharOffset(element) {
    var caretOffset = 0;
    try {

        if (window.getSelection) {
            var range = window.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }

        else if (document.selection && document.selection.type != "Control") {
            var textRange = document.selection.createRange();
            var preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }
    }
    catch (e) {
        return -1;
    }

    return caretOffset;
}
class ImprimirDiagramacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            enunciado: '',
            msgErroTextoApoio: '',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            inicio: moment(),
            processo: { name: '' },
            arquivos: [],
            texto: '',
            titulo: '',
            texto_value: '',
            questao_enunciado: '',
            show_autocomplete_questao: false,
            loading_search_questao: false,
            questoes: [],
            questoes_selecionadas: {},
            categorias: [],
            provas: [],
            categoria: { id: '' },
            provaSelect: null,

            showModal: false,
            questaoSelect: null,
            loading_get_all: false,

            questoes_array: [],
            tipos_prova: [
                {
                    id: 'multipla_escolha',
                    name: 'Prova objetiva'
                },
                // {
                //     id:'dissertativa',
                //     name:'Prova dissertativa'
                // }
            ],
            type: 'multipla_escolha',
            loading_image: false,
            logo: '',
            logo_inferior: '',

            titulo: '',
            subtitulo: '',
            titulo_pagina: "",
            descricao: '',
            pages: [],
            turno: '',
            nome: '',
            subtitulo_pagina: '',
            instrucoes: '',
            offsetHeight: 0,
            fontSizeQuestoes: 13,
            fontSizeAlternativas: 13,
            numero_colunas: 1,
            fontSizeInstrucoes: 14,
            numero_colunas_instrucoes: 1,
            loading_page: true,
            loading_pdf: false,
            pages_new: [],
            generate_pdf_image: false,

            marginInternaSuperior: 14,
            marginInternaDireita: 40,
            marginInternaEsquerda: 40,
            marginInternaInferior: 40,
            show_category: true,
            barra_dupla: true,
            default_diagramacao: false,
            campo_1: '',
            campo_2: '',
            titulo_campo_1: '',
            titulo_campo_2: '',
            titulo_cabecalho: '',
            fontSizeCabecalhoFolha: 20,
            readcontent: true,
            dataFrontPage: [],
            dataIndex: -1,
            config: {

            },
            include_blank:true,
            numero_colunas_page:{}

        };



        this.pages = [];
        this.page_content = {}
        this.page_content_text = {}
        this.pages_new = [];
        this.config = {
            height: 300,
            // askBeforePasteHTML:false,
            // askBeforePasteFromWord:false,
            // defaultActionOnPaste:'insert_only_text',
            // defaultActionOnPasteFromWord:'insert_only_text',
            // pasteHTMLActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            // pasteFromWordActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            readonly: this.state.loading_image,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


    }





    parseHtmlString(yourHtmlString) {
        var element = document.createElement('div');
        element.innerHTML = yourHtmlString;
        let srcs = []
        var imgSrcUrls = element.getElementsByTagName("img");
        for (var i = 0; i < imgSrcUrls.length; i++) {
            var urlValue = imgSrcUrls[i].getAttribute("src");
            srcs.push(urlValue);
        }
        return srcs;
    }


    readInitialImages() {
        let outputFormat = 'image/png';
        this.setState({ loading_page: true });

        let questoes = [...this.state.provaSelect.questoes];
        let finish = {};
        for (let i = 0; i < questoes.length; i++) {
            let questao = questoes[i];
            questoes[i].questoes = [...questoes[i].questoes];
            questoes[i].alternativas = [...questoes[i].alternativas];

            if (questao.type_questao == 'simples') {
                let srcs = this.parseHtmlString(questao.name);
                for (let k = 0; k < srcs.length; k++) {
                    let r = k;
                    finish['q_' + r + '_' + questao.id] = false;
                    let src = srcs[k];
                    let img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.onload = function () {
                        var canvas = document.createElement('CANVAS');
                        var ctx = canvas.getContext('2d');
                        var dataURL;
                        canvas.height = this.naturalHeight;
                        canvas.width = this.naturalWidth;
                        ctx.drawImage(this, 0, 0);
                        dataURL = canvas.toDataURL(outputFormat);
                        questoes[i].name = questoes[i].name.replace(src, dataURL);
                        finish['q_' + r + '_' + questao.id] = true;

                    };
                    img.src = src;
                }

                for (let j = 0; j < questao.alternativas.length; j++) {
                    let alternativa = questao.alternativas[j];
                    let srcs = this.parseHtmlString(alternativa.name);
                    for (let k = 0; k < srcs.length; k++) {
                        let r = k;
                        finish['a_' + r + '_' + alternativa.id] = false;

                        let src = srcs[k];
                        let img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = function () {
                            var canvas = document.createElement('CANVAS');
                            var ctx = canvas.getContext('2d');
                            var dataURL;
                            canvas.height = this.naturalHeight;
                            canvas.width = this.naturalWidth;
                            ctx.drawImage(this, 0, 0);
                            dataURL = canvas.toDataURL(outputFormat);
                            questoes[i].alternativas[j].name = questoes[i].alternativas[j].name.replace(src, dataURL);
                            finish['a_' + r + '_' + alternativa.id] = true;

                        };
                        img.src = src;
                    }
                }

            }
            else if (questao.type_questao == 'conjunto') {
                let srcs = this.parseHtmlString(questao.name);
                for (let k = 0; k < srcs.length; k++) {
                    let r = k;
                    finish['q_' + r + '_' + questao.id] = false;
                    let src = srcs[k];
                    let img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.onload = function () {
                        var canvas = document.createElement('CANVAS');
                        var ctx = canvas.getContext('2d');
                        var dataURL;
                        canvas.height = this.naturalHeight;
                        canvas.width = this.naturalWidth;
                        ctx.drawImage(this, 0, 0);
                        dataURL = canvas.toDataURL(outputFormat);
                        questoes[i].name = questoes[i].name.replace(src, dataURL);
                        finish['q_' + r + '_' + questao.id] = true;

                    };
                    img.src = src;
                }

                for (let y = 0; y < questao.questoes.length; y++) {
                    questoes[i].questoes[y].alternativas = [...questoes[i].questoes[y].alternativas];

                    let questao2 = questao.questoes[y];
                    let srcs = this.parseHtmlString(questao2.name);

                    for (let k = 0; k < srcs.length; k++) {
                        let src = srcs[k];
                        let r = k
                        finish['q_' + r + '_' + questao2.id] = false;
                        console.log(src)
                        let img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = function () {
                            var canvas = document.createElement('CANVAS');
                            var ctx = canvas.getContext('2d');
                            var dataURL;
                            canvas.height = this.naturalHeight;
                            canvas.width = this.naturalWidth;
                            ctx.drawImage(this, 0, 0);
                            dataURL = canvas.toDataURL(outputFormat);
                            console.log(dataURL);

                            questoes[i].questoes[y].name = questoes[i].questoes[y].name.replace(src, dataURL);
                            finish['q_' + r + '_' + questao2.id] = true;

                        };
                        img.src = src;
                    }

                    for (let j = 0; j < questao2.alternativas.length; j++) {
                        let alternativa = questao2.alternativas[j];
                        let srcs = this.parseHtmlString(alternativa.name);

                        for (let k = 0; k < srcs.length; k++) {
                            let r = k
                            finish['a_' + r + '_' + alternativa.id] = false;

                            let src = srcs[k];
                            let img = new Image();
                            img.crossOrigin = 'Anonymous';
                            img.onload = function () {
                                var canvas = document.createElement('CANVAS');
                                var ctx = canvas.getContext('2d');
                                var dataURL;
                                canvas.height = this.naturalHeight;
                                canvas.width = this.naturalWidth;
                                ctx.drawImage(this, 0, 0);
                                dataURL = canvas.toDataURL(outputFormat);
                                questoes[i].questoes[y].alternativas[j].name = questoes[i].questoes[y].alternativas[j].name.replace(src, dataURL);
                                finish['a_' + r + '_' + alternativa.id] = true;
                            };
                            img.src = src;
                        }
                    }
                }

            }
        }
        let counter = 0;
        this.inverval_load = setInterval(() => {
            let interval_actual = this.inverval_load;
            if (!Object.values(finish).includes(false)) {
                clearInterval(this.inverval_load);
                clearInterval(interval_actual);

                let prova = { ...this.state.provaSelect };
                prova.questoes = [...questoes];
                this.setState({ provaSelect: prova }, () => {
                    this.setPages();
                });
            }
            else {
                console.log("executando interval entrou else");

                counter++;
                if (counter > 3) {
                    let prova = { ...this.state.provaSelect };
                    prova.questoes = [...questoes];

                    clearInterval(this.inverval_load);
                    clearInterval(interval_actual);
                    this.setState({ provaSelect: prova }, () => {
                        this.setPages();
                    });
                }
            }
        }, 1000);


    }

    count_diagramacao() {
        this.setState({ loading_save: true, msgErro: '' });




        fetch(`${URL}api/faculdade/count_diagramacao/${this.props.diagramacao.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({})
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else if (resp.message != undefined) {
                    setTimeout(() => {
                        this.count_diagramacao();
                    }, 15000);
                    return;
                }
                else {


                    this.setState({ loading_save: false }, () => {
                        document.getElementById('container_print').className = '';
                        window.print();
                        setTimeout(() => {
                            document.getElementById('container_print').className = 'hidethis';

                        }, 500);
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    setPages() {
        this.setState({numero_colunas_page:{}})
        try {
            // let width_one_column = (668 - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40));
            // let width_two_column = 326 - ((this.state.marginInternaDireita - 40) + (this.state.marginInternaEsquerda - 40) / 2);
            let width_one_column = (771 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda));
            let width_two_column = ((755 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda)) / 2);

            this.setState({ loading_page: true });
            // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
            // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
            let height_page = this.state.numero_colunas == 1
                ? 1103 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67 - 20
                : (1103 - 20 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67) * 2;
            // let height_page = 1200;
            console.log('Height page: ', height_page)

            let default_margin = 0;
            let pages = [];
            let questoes = this.state.provaSelect.questoes;
            let page = null;
            for (let i = 0; i < questoes.length; i++) {
                let questao = questoes[i];
                if (page == null) {
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []
                    }
                }
                //checa categoria
                if (document.getElementById('categoria_' + questao.id) != null && page.height + Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight + default_margin) < height_page) {
                    page.height += Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin;
                    page.elements.push(document.getElementById('categoria_' + questao.id).cloneNode(true))
                    page.heights.push(Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin)

                    page.ids.push('categoria_' + questao.id);
                }
                else if (document.getElementById('categoria_' + questao.id) != null) {
                    pages.push(page);
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []

                    }
                    if (document.getElementById('categoria_' + questao.id) != null && page.height + Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin;
                        page.elements.push(document.getElementById('categoria_' + questao.id).cloneNode(true))
                        page.ids.push('categoria_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('categoria_' + questao.id).offsetHeight) + default_margin)
                    }
                }

                if (page.height + Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin < height_page) {
                    page.height += Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin;
                    let element2 = document.getElementById('enunciado_' + questao.id).cloneNode(true);
                    element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                    page.elements.push(element2)
                    page.ids.push('enunciado_' + questao.id);
                    page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin);

                }
                else {
                    pages.push(page);
                    page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: []

                    }
                    // if (page.height + Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin;
                        let element2 = document.getElementById('enunciado_' + questao.id).cloneNode(true);
                        element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                        page.elements.push(element2)
                        page.ids.push('enunciado_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao.id).offsetHeight) + default_margin);
                    // }
                }

                if (questao.type_questao == 'simples') {
                    for (let j = 0; j < questao.alternativas.length; j++) {
                        let alternativa = questao.alternativas[j];
                        if (page.height + Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                            let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                            element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            page.elements.push(element2)
                            page.ids.push('alternativa_' + alternativa.id);
                            page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);
                        }
                        else {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            // if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                                let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                page.elements.push(element2)
                                page.ids.push('alternativa_' + alternativa.id);
                                page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);
                            // }
                        }
                    }

                    if (document.getElementById('dividor_' + questao.id) != null && page.height + document.getElementById('dividor_' + questao.id).offsetHeight + 20 + default_margin < height_page) {
                        page.height += Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight + 20) + default_margin;
                        page.elements.push(document.getElementById('dividor_' + questao.id).cloneNode(true))
                        page.ids.push('dividor_' + questao.id);
                        page.heights.push(Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight + 20) + default_margin);

                    }
                    else if (document.getElementById('dividor_' + questao.id) != null) {
                        pages.push(page);
                        page = {
                            ids: [],
                            elements: [],
                            height: 0,
                            heights: []
                        }
                        // if (document.getElementById('dividor_' + questao.id) != null && page.height + document.getElementById('dividor_' + questao.id).offsetHeight + 20 + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight + 20) + default_margin;
                            page.elements.push(document.getElementById('dividor_' + questao.id).cloneNode(true))
                            page.ids.push('dividor_' + questao.id);
                            page.heights.push(Math.ceil(document.getElementById('dividor_' + questao.id).offsetHeight + 20) + default_margin);
                        // }
                    }

                }
                else if (questao.type_questao == 'conjunto') {
                    for (let k = 0; k < questao.questoes.length; k++) {
                        let questao_child = questao.questoes[k];
                        if (page.height + document.getElementById('enunciado_' + questao_child.id).offsetHeight + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin;
                            let element2 = document.getElementById('enunciado_' + questao_child.id).cloneNode(true);
                            element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                            page.elements.push(element2)
                            page.ids.push('enunciado_' + questao_child.id);
                            page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin);
                        }
                        else {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            // if (page.height + document.getElementById('enunciado_' + questao_child.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin;
                                let element2 = document.getElementById('enunciado_' + questao_child.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                page.elements.push(element2)
                                page.ids.push('enunciado_' + questao_child.id);
                                page.heights.push(Math.ceil(document.getElementById('enunciado_' + questao_child.id).offsetHeight) + default_margin);
                            // }
                        }



                        for (let j = 0; j < questao_child.alternativas.length; j++) {
                            let alternativa = questao_child.alternativas[j];
                            if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;

                                let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                page.elements.push(element2)
                                page.ids.push('alternativa_' + alternativa.id);
                                page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);

                            }
                            else {
                                pages.push(page);
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []
                                }
                                // if (page.height + document.getElementById('alternativa_' + alternativa.id).offsetHeight + default_margin < height_page) {
                                    page.height += Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin;
                                    let element2 = document.getElementById('alternativa_' + alternativa.id).cloneNode(true);
                                    element2.firstChild.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');


                                    page.elements.push(element2)
                                    page.ids.push('alternativa_' + alternativa.id);
                                    page.heights.push(Math.ceil(document.getElementById('alternativa_' + alternativa.id).offsetHeight) + default_margin);

                                // }
                            }
                        }

                        if (document.getElementById('dividor_' + questao_child.id) != null && page.height + document.getElementById('dividor_' + questao_child.id).offsetHeight + 20 + default_margin < height_page) {
                            page.height += Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight + 20) + default_margin;
                            page.elements.push(document.getElementById('dividor_' + questao_child.id).cloneNode(true))
                            page.ids.push('dividor_' + questao_child.id);
                            page.heights.push(Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight + 20) + default_margin);

                        }
                        else if (document.getElementById('dividor_' + questao_child.id) != null) {
                            pages.push(page);
                            page = {
                                ids: [],
                                elements: [],
                                height: 0,
                                heights: []
                            }
                            // if (document.getElementById('dividor_' + questao_child.id) != null && page.height + document.getElementById('dividor_' + questao_child.id).offsetHeight + 20 + default_margin < height_page) {
                                page.height += Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight + 20) + default_margin;
                                page.elements.push(document.getElementById('dividor_' + questao_child.id).cloneNode(true))
                                page.ids.push('dividor_' + questao_child.id);
                                page.heights.push(Math.ceil(document.getElementById('dividor_' + questao_child.id).offsetHeight + 20) + default_margin);
                            // }
                        }
                    }
                }
            }
            if (page != null && page.height != 0) {
                pages.push(page);
            }

            console.log(pages)
            console.log('iniciou')
            this.pages = [];
            let length_elements = [];
            this.pages_new = []

            this.setState({ pages, pages_new: [] }, () => {
                this.timeout = setTimeout(() => {
                    try {

                        console.log(pages);
                        console.log(this.pages);
                        pages = [];
                        default_margin = 0;
                        let line_height = 60;

                        page = null;
                        let heights = [];
                        for (let i = 0; i < this.pages.length; i++) {
                            let element = this.pages[i];
                            let aditional = 0;
                            if (document.getElementById(element).classList.contains('dividor')) {
                                aditional += 20;
                            }
                            if (page == null) {
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []

                                }
                            }
                            //checa categoria
                            if (true == false) {
                                if (page.height < height_page / 2 && document.getElementById(element).innerHTML.includes('<img ') && page.height + Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin > (height_page / 2) && this.state.numero_colunas != 1) {
                                    let div_element = document.createElement('div');
                                    div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                                    // div_element.style.background='yellow';
                                    div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                    let id = uuid();
                                    let new_div = div_element.cloneNode();
                                    new_div.id = id;

                                    page.height += (height_page / 2) - page.height;
                                    heights.push((height_page / 2) - page.height)
                                    page.elements.push(new_div)
                                    page.ids.push(id);
                                    page.heights.push((height_page / 2) - page.height)
                                    console.log(id);
                                }
                                if (page.height < height_page / 2 && page.height + line_height + default_margin > (height_page / 2) && this.state.numero_colunas != 1) {
                                    let div_element = document.createElement('div');
                                    div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                                    // div_element.style.background='yellow';
                                    div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                                    let id = uuid();
                                    let new_div = div_element.cloneNode();
                                    new_div.id = id;

                                    page.height += (height_page / 2) - page.height;
                                    heights.push((height_page / 2) - page.height)
                                    page.elements.push(new_div)
                                    page.ids.push(id);
                                    page.heights.push((height_page / 2) - page.height)

                                    console.log(id);
                                }
                            }
                            // if (
                            //     document.getElementById(element).classList.toString().includes('categoria') &&
                            //     this.pages[i + 1] != undefined &&
                            //     (page.height + Math.round(document.getElementById(element).offsetHeight + document.getElementById(this.pages[i + 1]).offsetHeight) + default_margin > (height_page / 2) && page.height < height_page / 2)

                            //     && this.state.numero_colunas != 1) {
                            //     let div_element = document.createElement('div');
                            //     div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
                            //     // div_element.style.background='yellow';
                            //     div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            //     let id = uuid();
                            //     let new_div = div_element.cloneNode();
                            //     new_div.id = id;

                            //     page.height += (height_page / 2) - page.height;
                            //     heights.push((height_page / 2) - page.height)
                            //     page.elements.push(new_div)
                            //     page.ids.push(id);
                            //     page.heights.push((height_page / 2) - page.height)

                            //     console.log(id);
                            // }

                            // if (
                            //     document.getElementById(element).classList.toString().includes('categoria') &&
                            //     this.pages[i + 1] != undefined &&
                            //     (page.height + Math.round(document.getElementById(element).offsetHeight + document.getElementById(this.pages[i + 1]).offsetHeight) + default_margin > height_page)

                            //     && this.state.numero_colunas != 1) {

                            //     let div_element = document.createElement('div');
                            //     div_element.style.height = ((height_page) - page.height - 1) + 'px';
                            //     // div_element.style.background='yellow';
                            //     div_element.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

                            //     let id = uuid();
                            //     let new_div = div_element.cloneNode();
                            //     new_div.id = id;

                            //     page.height += (height_page) - page.height;
                            //     heights.push((height_page) - page.height)
                            //     page.elements.push(new_div)
                            //     page.ids.push(id);
                            //     page.heights.push((height_page) - page.height)

                            //     console.log(id);
                            //     pages.push(page);
                            //     page = {
                            //         ids: [],
                            //         elements: [],
                            //         height: 0,
                            //         heights: []

                            //     }
                            // }

                            if (page.height + Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin < height_page) {
                                page.height += Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin;
                                heights.push(Math.round(document.getElementById(element).offsetHeight + aditional))
                                let element2 = document.getElementById(element).cloneNode(true);
                                element2.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                // element2.style.fontWeight='bold';
                                if (element2.tagName == 'DIV' && (element2.classList.contains('questao') || element2.classList.contains('alternativa'))) {
                                    element2.className = element2.className + ' ' + element2.children[0].children[0].className;
                                    element2.style.fontSize = element2.children[0].children[0].style.fontSize;
                                    element2.innerHTML = element2.children[0].children[0].innerHTML;

                                }
                                page.elements.push(element2)
                                page.ids.push(element);
                                page.heights.push(Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin)

                                // if (pages.length == 7) {
                                //     length_elements.push(document.getElementById(element).offsetHeight)
                                // }
                            }
                            else {
                                pages.push(page);
                                page = {
                                    ids: [],
                                    elements: [],
                                    height: 0,
                                    heights: []

                                }
                                // if (page.height + Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin < height_page) {

                                    heights.push(Math.round(document.getElementById(element).offsetHeight + aditional))

                                    page.height += Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin;
                                    let element2 = document.getElementById(element).cloneNode(true);
                                    element2.style.maxWidth = this.state.numero_colunas == 1 ? (width_one_column + 'px') : (width_two_column + 'px');
                                    if (element2.tagName == 'DIV' && (element2.classList.contains('questao') || element2.classList.contains('alternativa'))) {
                                        element2.className = element2.className + ' ' + element2.children[0].children[0].className;
                                        element2.style.fontSize = element2.children[0].children[0].style.fontSize;
                                        element2.innerHTML = element2.children[0].children[0].innerHTML;

                                    }
                                    page.elements.push(element2)
                                    page.ids.push(element);
                                    page.heights.push(Math.round(document.getElementById(element).offsetHeight + aditional) + default_margin)
                                    // if (pages.length == 7) {
                                    //     length_elements.push(document.getElementById(element).offsetHeight)
                                    // }
                                // }
                            }
                        }
                        if (page != null && page.height != 0) {
                            page.value = '';
                            pages.push(page);
                        }
                        console.log('ELEMENTS')
                        pages = pages.map((item, id) => {
                            item.value = '';
                            item.new_ids = [];

                            item.value_content = '';

                            item.elements.map((elem, index) => {
                                elem.id = 1 + '_' + id + '_' + index;
                                item.new_ids.push(elem.id)
                                let value='';
                                elem.children.forEach((item2)=>{
                                    item2.className=item2.className+' '+elem.className;
                                    item2.style.cssText=item2.style.cssText+''+elem.style.cssText;
                                    // item2.innerHTML=item2.innerHTML.replaceAll('\n',' ');
                                    
                                    if (item2.tagName!='HR' && item2.textContent.replaceAll('\n',' ').replaceAll(' ','') == '' && ((!item2.outerHTML.includes('<br') && !item2.outerHTML.includes('<img') && !item2.outerHTML.includes('<table')))) {
                                        console.log('REMOVED')
                                        console.log(item2.cloneNode(true));
                                        console.log('PAGE',id)
                                        // item2.remove();
                                    }
                                    else{
                                        value+=item2.outerHTML;
                                    }
                                })
                                if(elem.innerHTML.includes('TEXTO PARA AS QUESTÕES 08, 09 e 10')){
                                    console.log('Node')
                                    console.log(elem.innerHTML)
                                }
                                if(elem.classList.contains('dividor')){
                                    item.value += elem.outerHTML;
                                }
                                else{
                                    item.value += value;
                                }
                                item.value_content += elem.textContent;
                            })
                            item.elements=[];
                            this.page_content[id] = item.value;
                            this.page_content_text[id] = item.value_content;
                            // item.value = item.value.replaceAll('<div','<p').replaceAll('</div','<p')
                            return item;

                        })

                        // ref.innerHTML = '';

                        // this.page_content[id]=ref.innerHTML;
                        // this.page_content_text[id]=ref.textContent;
                        this.pages_new = pages;

                        this.setState({ pages_new: pages, pages: [], loading_page: false }, () => {
                            this.pages_new.map((item, id) => {
                                document.getElementById('page_content_' + id).innerHTML = item.value
                                // .replaceAll('<div','<p').replaceAll('</div','</p');
                            })
                            setTimeout(() => {
                                this.checkOverflow(0);
                            }, 500);
                            console.log('New pages')
                            console.log(pages)
                            console.log(length_elements)
                            // setTimeout(() => {
                            //     this.generatePDF();

                            // }, 1000)
                        });
                    }
                    catch (e) {
                        console.log(e);
                    }
                }, 1000);

            });
        }
        catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {

        clearTimeout(this.timeout);
    }


    componentDidMount() {
        if (this.props.showModal == true && this.props.diagramacao != null) {
            let resp = { diagramacao: this.props.diagramacao };
            try {
                this.pages_new = JSON.parse(resp.diagramacao.pages);

            } catch (e) {
                this.pages_new = []
            }

            let numero_colunas_page={}
                    try {
                        numero_colunas_page = JSON.parse(resp.diagramacao.numero_colunas_page);

                    } catch (e) {
                        numero_colunas_page={}
                    }

            console.log(resp);
            this.setState({
                loading_screen: false,
                provaSelect: resp.diagramacao.prova,
                pages_new: this.pages_new ?? [],
                numero_colunas_page,
                logo_path: resp.diagramacao.logo,
                logo_path_inferior: resp.diagramacao.logo_inferior ?? resp.diagramacao.logo,
                dataFrontPage: resp.diagramacao.elements.map((item,id)=>{
                    item.element=item.element.replaceAll('X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=','');
                    return item;
                }),

                titulo_pagina: resp.diagramacao.titulo_pagina,
                nome: resp.diagramacao.nome_prova,
                subtitulo_pagina: resp.diagramacao.subtitulo_pagina,
                campo_1: resp.diagramacao.campo_1 ?? '',
                campo_2: resp.diagramacao.campo_2 ?? '',
                titulo_campo_1: resp.diagramacao.titulo_campo_1 ?? '',
                titulo_campo_2: resp.diagramacao.titulo_campo_2 ?? '',
                numero_colunas_instrucoes: resp.diagramacao.numero_colunas_instrucoes ?? 1,
                fontSizeInstrucoes: resp.diagramacao.tamanho_fonte_instrucoes == null ? 14 : resp.diagramacao.tamanho_fonte_instrucoes,

                marginInternaSuperior: resp.diagramacao.margin_interna_superior,
                marginInternaDireita: resp.diagramacao.margin_interna_direita,
                marginInternaEsquerda: resp.diagramacao.margin_interna_esquerda,
                marginInternaInferior: resp.diagramacao.margin_interna_inferior,
                show_category: resp.diagramacao.show_category,
                barra_dupla: resp.diagramacao.barra_dupla,
                default_diagramacao: resp.diagramacao.default_diagramacao,

                titulo: resp.diagramacao.titulo_cabecalho ?? '',
                subtitulo: resp.diagramacao.subtitulo_cabecalho ?? '',
                instrucoes: resp.diagramacao.instrucoes,
                numero_colunas: resp.diagramacao.numero_colunas,
                fontSizeQuestoes: resp.diagramacao.tamanho_fonte_questoes == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_questoes),
                fontSizeAlternativas: resp.diagramacao.tamanho_fonte_alternativas == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_alternativas),
                fontSizeCabecalhoFolha: parseInt(resp.diagramacao.tamanho_fonte_cabecalho_folha),
                titulo_cabecalho: resp.diagramacao.titulo_cabecalho_folha ?? '',

            }, () => {
                console.log(this.state.provaSelect)
                if (this.state.provaSelect.id != '') {
                    if (this.pages_new.length > 0) {
                        this.setState({ loading_page: false })

                        this.pages_new.map((item, id) => {
                            document.getElementById('page_content_' + id).innerHTML = item.value
                            // .replaceAll('<div','<p').replaceAll('</div','</p');
                        })
                        this.checkOverflow(0);
                    }
                    else {
                        this.readInitialImages();
                    }

                }

            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (document.getElementById('header_page') != null && this.state.offsetHeight != document.getElementById('header_page').offsetHeight) {
            this.setState({ offsetHeight: document.getElementById('header_page').offsetHeight })
        }
        console.log('update');
        console.log(this.props.diagramacao);
        if (this.props.showModal == true && prevProps.showModal == false && this.props.diagramacao != null) {
            let resp = { diagramacao: this.props.diagramacao };
            console.log(resp);
            this.setState({
                loading_screen: false,
                provaSelect: resp.diagramacao.prova,
                logo_path: resp.diagramacao.logo,
                logo_path_inferior: resp.diagramacao.logo_inferior ?? resp.diagramacao.logo,

                titulo_pagina: resp.diagramacao.titulo_pagina,
                nome: resp.diagramacao.nome_prova,
                subtitulo_pagina: resp.diagramacao.subtitulo_pagina,
                campo_1: resp.diagramacao.campo_1 ?? '',
                campo_2: resp.diagramacao.campo_2 ?? '',
                titulo_campo_1: resp.diagramacao.titulo_campo_1 ?? '',
                titulo_campo_2: resp.diagramacao.titulo_campo_2 ?? '',
                numero_colunas_instrucoes: resp.diagramacao.numero_colunas_instrucoes ?? 1,
                fontSizeInstrucoes: resp.diagramacao.tamanho_fonte_instrucoes == null ? 14 : resp.diagramacao.tamanho_fonte_instrucoes,

                marginInternaSuperior: resp.diagramacao.margin_interna_superior,
                marginInternaDireita: resp.diagramacao.margin_interna_direita,
                marginInternaEsquerda: resp.diagramacao.margin_interna_esquerda,
                marginInternaInferior: resp.diagramacao.margin_interna_inferior,
                show_category: resp.diagramacao.show_category,
                barra_dupla: resp.diagramacao.barra_dupla,
                default_diagramacao: resp.diagramacao.default_diagramacao,

                titulo: resp.diagramacao.titulo_cabecalho,
                subtitulo: resp.diagramacao.subtitulo_cabecalho,
                instrucoes: resp.diagramacao.instrucoes,
                numero_colunas: resp.diagramacao.numero_colunas,
                fontSizeQuestoes: resp.diagramacao.tamanho_fonte_questoes == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_questoes),
                fontSizeAlternativas: resp.diagramacao.tamanho_fonte_alternativas == 'default' ? 'default' : parseInt(resp.diagramacao.tamanho_fonte_alternativas),
                fontSizeCabecalhoFolha: parseInt(resp.diagramacao.tamanho_fonte_cabecalho_folha),
                titulo_cabecalho: resp.diagramacao.titulo_cabecalho_folha ?? '',

            }, () => {
                console.log(this.state.provaSelect)
                if (this.state.provaSelect.id != '') {
                    this.readInitialImages();

                }

            });
        }
    }



    changeFontSizeQuestoes(questao) {
        if (this.state.fontSizeQuestoes == 'default') {
            return questao;
        }
        else {
            return questao.replace(/font-size/g, '');
        }
    }

    changeFontSizeAlts(questao) {
        if (this.state.fontSizeAlternativas == 'default') {
            return questao;
        }
        else {
            return questao.replace(/font-size/g, '');
        }
    }

    getAllChilds(el) {
        let children = Array.from(el.children);

        let data = {
            tag: el.tagName,
            style: el.style,
            className: el.className,
            id: el.id,
            children: children.map((item) => {
                return this.getAllChilds(item);
            })
        }

        return data;
    }

    checkOverflow(id) {
        let height_sample = 1103;
        if (document.getElementById('page_content_' + id) == null) {
            return;
        }
        this.pages_new[id].value = document.getElementById('page_content_' + id).innerHTML;
        console.log(this.pages_new[id].value);
        this.pages_new[id].value_content = document.getElementById('page_content_' + id).textContent;
        let isOverflowing = document.getElementById('page_content_' + id).clientWidth < document.getElementById('page_content_' + id).scrollWidth || document.getElementById('page_content_' + id).clientHeight < document.getElementById('page_content_' + id).scrollHeight;
        let index_page = id;
        let width_one_column = (771 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda));
        let width_two_column = ((755 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda)) / 2);
        while (isOverflowing || index_page < this.pages_new.length) {
            if (!isOverflowing) {
                index_page += 1;
                if (index_page >= this.pages_new.length) return;
                isOverflowing = document.getElementById('page_content_' + index_page).clientWidth < document.getElementById('page_content_' + index_page).scrollWidth || document.getElementById('page_content_' + index_page).clientHeight < document.getElementById('page_content_' + index_page).scrollHeight;
                console.log(index_page, isOverflowing)
            }

            if (isOverflowing) {

                let numero_colunas = this.state.numero_colunas_page[index_page]==undefined?this.state.numero_colunas:this.state.numero_colunas_page[index_page];
                
                let element = document.getElementById('page_content_' + index_page).children[document.getElementById('page_content_' + index_page).children.length - 1];
                let height_page = numero_colunas == 1
                    ? 1103 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67 - 20
                    : (1103 - 20 - this.state.marginInternaSuperior - this.state.marginInternaInferior - 67) * 2;
                if (element.offsetHeight > height_page) {
                    this.checkOverflow(index_page + 1)
                    break;
                    let all_childs = this.getAllChilds(element);
                    for (let j = all_childs.length - 1; j >= 0; j--) {

                    }

                }

                console.log('ELEMENT ID removed', element.id)
                element.remove();
                let element_removed;
                // console.log(element.tagName)
                if (element.tagName == 'HR') {
                    element_removed = document.createElement('hr');
                }
                else if(element.tagName == 'P') {
                    element_removed = document.createElement('p');
                }
                else{
                    element_removed = document.createElement(element.tagName);
                }
                numero_colunas = this.state.numero_colunas_page[index_page+1]==undefined?this.state.numero_colunas:this.state.numero_colunas_page[index_page+1];
                element_removed.style.cssText = element.style.cssText;
                element_removed.style.maxWidth=(numero_colunas==1?width_one_column:width_two_column)+'px';
                element_removed.className = element.className;
                element_removed.innerHTML = element.innerHTML;


                // element_removed.id = uuid();
                // for (let i = 0; i < element.children.length; i++) {
                //     element_removed.appendChild(element.children[i].cloneNode(true))
                // }
                element_removed.innerHTML = element_removed.innerHTML.replaceAll('<br>', '');

                console.log('REMOVED')
                console.log(element_removed);
                let aditional = 0;
                if (element_removed.classList.contains('dividor')) {
                    aditional = 20;
                }
                console.log(element_removed)
                element_removed.id = uuid();

                if (element.classList.contains('questao') || element.classList.contains('alternativa') || element.classList.contains('dividor') || element.classList.contains('categoria')) {
                    this.pages_new[index_page].ids.pop();
                    this.pages_new[index_page].new_ids.pop();

                    this.pages_new[index_page].heights.pop();
                    this.pages_new[index_page].elements.pop();
                    this.pages_new[index_page].height -= Math.ceil(element_removed.offsetHeight + aditional);
                    console.log('Page overflow: ', index_page)
                    console.log(this.pages_new[index_page].value)
                }
                this.pages_new[index_page].value = document.getElementById('page_content_' + (index_page)).innerHTML;
                this.pages_new[index_page].value_content = document.getElementById('page_content_' + (index_page)).textContent;
                if (element_removed.tagName!='HR' && element_removed.textContent == '' && ((!element_removed.outerHTML.includes('<br') && !element_removed.outerHTML.includes('<img') && !element_removed.outerHTML.includes('<table')))) {
                    element_removed.remove();
                    continue;
                }

                // element_removed.id = uuid();

                if (this.pages_new[index_page + 1] != undefined) {
                    this.pages_new[index_page + 1].ids.unshift(element_removed.id);
                    this.pages_new[index_page + 1].new_ids.unshift(element_removed.id);

                    this.pages_new[index_page + 1].elements.unshift(element_removed);
                    this.pages_new[index_page + 1].heights.unshift(Math.ceil(element_removed.offsetHeight + aditional));
                    this.pages_new[index_page + 1].height += Math.ceil(element_removed.offsetHeight + aditional);


                    document.getElementById('page_content_' + (index_page + 1)).prepend(element_removed)

                    this.pages_new[index_page + 1].value = document.getElementById('page_content_' + (index_page + 1)).innerHTML;
                    this.pages_new[index_page + 1].value_content = document.getElementById('page_content_' + (index_page + 1)).textContent;
                    document.getElementById('page_content_' + (index_page + 1)).innerHTML = document.getElementById('page_content_' + (index_page + 1)).innerHTML + '';
                }
                else {
                    let page = {
                        ids: [],
                        elements: [],
                        height: 0,
                        heights: [],
                        new_ids: []

                    }
                    page.ids.push(element_removed.id);
                    page.new_ids.push(element_removed.id);

                    page.elements.push(element_removed);
                    page.heights.push(Math.ceil(element_removed.offsetHeight + aditional));
                    page.height += Math.ceil(element_removed.offsetHeight + aditional);

                    page.value = element_removed.outerHTML;
                    page.value_content = element_removed.textContent;

                    this.pages_new.push(page);
                    this.setState({ pages_new: this.pages_new }, () => {
                        document.getElementById('page_content_' + (index_page + 1)).prepend(element_removed)
                        this.checkOverflow(index_page);
                    })
                    break;
                }
            }
            // e.preventDefault();
            // document.getElementById('page_content_' + id).innerHTML = this.page_content[id];
            isOverflowing = document.getElementById('page_content_' + index_page).clientWidth < document.getElementById('page_content_' + index_page).scrollWidth || document.getElementById('page_content_' + index_page).clientHeight < document.getElementById('page_content_' + index_page).scrollHeight;
            // console.log(index_page, isOverflowing)
            // return;



        }
    }

    render() {
        let height_sample = 1103;
        let width_sample = 777;

        let number_questao = 0;
        let width_one_column = 771;
        // let width_two_column = 326;
        let width_two_column = ((755 - (this.state.marginInternaDireita) - (this.state.marginInternaEsquerda)) / 2);

        this.pages = []

        return (

            this.state.provaSelect != null && <div id='container_print' className='hidethis' hidden={this.props.showModal == false} >
                <StyleQuestion />
                {this.state.loading_page && <div style={{ zIndex: 10, background: 'rgba(255,255,255,0.5)', position: 'absolute', top: 0, height: '100%', left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div>}
                <div className='hidethis' style={{ width: '100%', paddingBottom: 20 }}>
                    {this.state.msgErro != '' && <div className='hidethis' style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                        <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                            <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>{this.state.msgErro}</p>

                        </div>
                        <br />

                    </div>}
                    {this.state.loading_page == false && <div className="w100 inline-flex jcsb margin-top-1 hidethis">
                        {/* <DefaultButton
                                                                        bg="secondary"
                                                                        text="Voltar"
                                                                        onClick={() => {
                                                                            // this.generatePDF();
                                                                            this.props.setShowModal(false);
                                                                        }}
                                                                        icon={<RiArrowGoBackLine />}
                                                                    /> */}
                        {this.state.loading_save == true && <div className="spinner-border text-primary" role="status"  >
                        </div>}
                                                                  
                                            <Form.Label>Incluir folha em branco após folha de rosto? <FormCheck
                                                type='checkbox'
                                                disabled={this.state.loading_pdf}
                                                checked={this.state.include_blank}
                                                onChange={(e) => this.setState({ include_blank: e.target.checked }, () => {
                                                })}
                                                
                                            // title="Coloque o nome completo do usuário"
                                            /> </Form.Label>
                                            
                                        <div>
                        {this.state.loading_save == false && <DefaultButton
                            type="button"
                            bg="info"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                // this.generatePDF();
                                this.count_diagramacao();
                            }}
                            text="Imprimir"
                            disabled={this.state.loading_pdf == true}
                            loadingtext="Imprindo..."
                            loading={this.state.loading_pdf}
                        />}
                        </div>

                    </div>}
                </div>

                <div style={{ position: 'relative', border: '1px solid grey' }}>

                    <div className='print_preview' style={{ maxHeight: this.state.loading_page ? 600 : 'none', overflow: this.state.loading_page ? 'scroll' : 'visible', width: '100%', display: 'flex', justifyContent: 'center', background: '#f2f2f2', position: 'relative' }}>

                        <div style={{ background: 'white', padding: 7.5, paddingTop: this.state.generate_pdf_image == false ? 0 : 15, paddingBottom: this.state.generate_pdf_image == false ? 0 : 15, height: '100%' }}>
                            <div id="pdf_diagramado_2" >

                                <div style={{ height: 1120,width:width_sample, position: 'relative',overflow:'hidden' }} >
                                    {/* <QRCode style={{
                                                                                position: "absolute",
                                                                                right: 25,
                                                                                top: 15,
                                                                            }} size={60} value={this.state.provaSelect.name} /> */}
                                    <div hidden className='hidethis' id={"meio_x"} style={{ position: 'absolute', left: 777 / 2, top: 0, height: 1120, width: 1, borderRight: '2px dashed red' }}>

                                    </div>
                                    <div hidden className='hidethis' id={"meio_y"} style={{ position: 'absolute', top: 560, bottom: 0, height: 1, width: 777, borderTop: '2px dashed red' }}>

                                    </div>
                                    {this.state.dataFrontPage.map((item, id) => (
                                        <Rnd

                                            key={id}
                                            size={{ width: item.width, height: item.height }}
                                            position={{ x: item.x, y: item.y }}

                                        >
                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.element }}></div>
                                        </Rnd>
                                    ))}

                                </div>
                                {this.state.include_blank==true && <div style={{ height: 1120,width:width_sample, position: 'relative' }} >
                                </div>}


                                <div hidden={this.state.readcontent == true} id={"page_0"} style={{ height: 1120 }}>

                                    <div hidden={this.state.readcontent == true} style={{ paddingTop: this.state.generate_pdf_image == false ? 15 : 0, paddingBottom: this.state.generate_pdf_image == false ? 15 : 0 }} >

                                        <div style={{ padding: 0, width: width_sample, background: 'white', marginTop: 0, marginBottom: 0, paddingTop: 0 }}>
                                            <div style={{ height: 960 + 123 }} >
                                                <div id='header_page'>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 0 }}>
                                                        <div>

                                                            <img style={{ height: 100, width: 150, objectFit: 'contain' }} src={this.state.logo == '' ? (STORAGE_URL + this.state.logo_path) : (window.URL.createObjectURL(this.state.logo))} />
                                                        </div>

                                                        <div style={{ maxWidth: 350, maxHeight: 100 }}>
                                                            <p style={{ textAlign: 'right', fontSize: this.state.fontSizeCabecalhoFolha, marginBottom: 0, color: 'black', fontWeight: 'bold' }}>{this.state.titulo_cabecalho}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '100%', height: 1, background: 'black', marginTop: 5, marginBottom: 5 }}></div>
                                                    <div style={{ position: "relative" }}>
                                                        <p
                                                            style={{
                                                                textAlign: "left",
                                                                fontSize: 15,
                                                                marginBottom: 5,
                                                                marginLeft: 20,
                                                                color: "black",
                                                                fontWeight: "normal",
                                                            }}
                                                        >
                                                            <b className='hidethis'>{"Nome do Candidato"}</b>&nbsp;
                                                        </p>
                                                        <p
                                                            style={{
                                                                textAlign: "left",
                                                                fontSize: 15,
                                                                marginBottom: 5,
                                                                marginLeft: 20,
                                                                color: "black",
                                                                fontWeight: "normal",
                                                            }}
                                                        >
                                                            <b className='hidethis'>{"Inscrição"}</b>&nbsp;
                                                        </p>
                                                        <p
                                                            style={{
                                                                textAlign: "left",
                                                                fontSize: 15,
                                                                marginBottom: 5,
                                                                marginLeft: 20,
                                                                color: "black",
                                                                fontWeight: "normal",
                                                            }}
                                                        >
                                                            <b className='hidethis'>{"CPF"}</b>&nbsp;
                                                        </p>
                                                        <QRCode style={{
                                                            position: "absolute",
                                                            right: 25,
                                                            top: 5,
                                                        }} size={60} value={this.state.provaSelect.name} />
                                                        {/* <img

                                                                                src={
                                                                                    "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                                                                }
                                                                                height={60}
                                                                                width={60}
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    right: 25,
                                                                                    top: 5,
                                                                                }}
                                                                            /> */}
                                                    </div>
                                                    <div style={{}}>
                                                        <p style={{ textAlign: 'center', fontSize: 30, marginBottom: 5, color: 'black', fontWeight: 'bold' }}>{this.state.titulo_pagina}</p>
                                                        <p style={{ textAlign: 'center', fontSize: 20, marginBottom: 5, color: 'black', fontWeight: 'bold' }}>{this.state.subtitulo_pagina}</p>
                                                        <p style={{ textAlign: 'center', fontSize: 25, marginBottom: 0, color: 'black', fontWeight: 'bold' }}>{this.state.nome}</p>

                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
                                                        {this.state.campo_1.trim().length != 0 && <div style={{ border: '1px solid black', borderRightWidth: this.state.campo_2.trim().length == 0 ? 2 : 0, padding: 5, width: this.state.campo_2.trim().length == 0 ? '100%' : '50%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginBottom: 0, fontSize: 17 }}><b>{this.state.titulo_campo_1}: </b>{this.state.campo_1}</p>
                                                        </div>}
                                                        {this.state.campo_2.trim().length != 0 && <div style={{ border: '1px solid black', padding: 5, width: this.state.campo_1.trim().length == 0 ? '100%' : '50%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginBottom: 0, fontSize: 17 }}><b>{this.state.titulo_campo_2}: </b>{this.state.campo_2}</p>
                                                        </div>}
                                                    </div>
                                                </div>

                                                <div style={{ height: 960 + 67 - 20 - this.state.offsetHeight, border: '2px solid black' }}>
                                                    <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black' }}>
                                                        <p style={{ marginBottom: 0, fontSize: 25, color: 'white', textAlign: 'center' }}><b>INSTRUÇÕES GERAIS</b></p>
                                                    </div>
                                                    <div style={{ height: 960 + 67 - 78 - this.state.offsetHeight, padding: 10, columnCount: this.state.numero_colunas_instrucoes, columnGap: 20, columnFill: 'auto', columnRule: 'double' }}>
                                                        <p style={{ fontSize: this.state.fontSizeInstrucoes, whiteSpace: 'pre-line', textAlign: 'justify', color: 'black' }}>{this.state.instrucoes}</p>
                                                    </div>

                                                </div>
                                                <div >
                                                    <p style={{ marginBottom: 0, fontSize: 14, color: 'black', textAlign: 'center' }}>Realização</p>

                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                        <img style={{ height: 50, maxWidth: '100%', objectFit: 'contain' }} src={this.state.logo_inferior == '' ? (STORAGE_URL + this.state.logo_path_inferior) : (window.URL.createObjectURL(this.state.logo_inferior))} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {this.state.pages.map((item, id) => (
                                    <div key={id} id={"page_" + (id + 1)} >
                                        <div style={{ paddingTop: this.state.generate_pdf_image == false ? 10 : 0, paddingBottom: this.state.generate_pdf_image == false ? 0 : 0, height: 1113, }}>

                                        <div style={{ height: 1103, padding: 0, marginLeft: this.state.marginInternaEsquerda - 40, marginRight: this.state.marginInternaDireita - 40, position: 'relative', width: (this.state.numero_colunas_page[id]==undefined?this.state.numero_colunas:this.state.numero_colunas_page[id]) == 1 ? (width_one_column - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40)) : (width_two_column), background: 'white', marginTop: 0, marginBottom: 0, border: '0px double black', paddingTop: this.state.marginInternaSuperior }}>
                                        <div style={{ height: 67 }}>
                                                    <p style={{ marginBottom: 5, textAlign: 'center', fontSize: 14 }}>&nbsp;{this.state.titulo}&nbsp;</p>
                                                    <p style={{ marginBottom: 20, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>&nbsp;{this.state.subtitulo}&nbsp;</p>

                                                </div>
                                                <div ref={(ref) => {
                                                    if (ref != null) {

                                                        ref.innerHTML = '';
                                                        item.elements.map((elem, index) => {
                                                            if (elem.id.includes('categoria_')) {
                                                                elem.className = 'categoria';
                                                            }
                                                            else if (elem.id.includes('dividor_')) {
                                                                elem.className = 'dividor';
                                                            }
                                                            else if (elem.id.includes('questao_')) {
                                                                elem.className = 'questao';
                                                            }
                                                            else if (elem.id.includes('enunciado_')) {
                                                                elem.className = 'questao';
                                                            }
                                                            else if (elem.id.includes('alternativa_')) {
                                                                elem.className = 'alternativa';
                                                            }
                                                            elem.style.textAlign = 'justify';
                                                            elem.id = 0 + '_' + id + '_' + index;
                                                            if (ref != null) {
                                                                ref.appendChild(elem);
                                                            }
                                                            this.pages.push(0 + '_' + id + '_' + index)
                                                        })

                                                    }
                                                }} style={{
                                                    columnCount: 1, columnFill: 'auto', height:
                                                        height_sample -
                                                        this.state.marginInternaSuperior -
                                                        this.state.marginInternaInferior -
                                                        67 - 20,
                                                }} >

                                                </div>
                                                <div style={{ position: 'absolute', bottom: 10, right: 0, left: 0 }} >
                                                    <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 12 }}>Página {id + 1} de {this.state.pages.length}</p>
                                                </div>


                                            </div>
                                        </div>

                                        {/* <div className='nextpage1'></div> */}
                                    </div>
                                ))}


                                {this.state.pages_new.map((item, id) => (
                                    <div key={id} style={{ height: 1120 }} id={"page_" + (id + 1)}>

                                        <div style={{ paddingTop: this.state.generate_pdf_image == false ? 10 : 0, paddingBottom: this.state.generate_pdf_image == false ? 0 : 0, height: 1113, }}>

                                            <div style={{
                                                height: 1103,
                                                paddingLeft: this.state.marginInternaEsquerda,
                                                paddingBottom: this.state.marginInternaInferior,
                                                paddingRight: this.state.marginInternaDireita,
                                                position: 'relative', width: width_sample,
                                                background: 'white',
                                                marginTop: 0,
                                                marginBottom: 0,
                                                border: '3px double ' + (this.state.barra_dupla == true ? 'black' : 'white'),
                                                paddingTop: this.state.marginInternaSuperior,
                                            }}>
                                                <div style={{ height: 67 }}>
                                                    <p style={{ marginBottom: 5, textAlign: 'center', fontSize: 14 }}>&nbsp;{this.state.titulo}&nbsp;</p>
                                                    <p style={{ marginBottom: 20, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>&nbsp;{this.state.subtitulo}&nbsp;</p>

                                                </div>
                                                <div ref={(ref) => {
                                                    if (ref != null) {
                                                        // ref.innerHTML = '';
                                                        // item.elements.map((elem, index) => {
                                                        //     elem.id = 1 + '_' + id + '_' + index;
                                                        //     if (ref != null) {
                                                        //         ref.appendChild(elem);
                                                        //     }
                                                        //     this.pages.push(1 + '_' + id + '_' + index)
                                                        // })
                                                        // this.page_content[id]=ref.innerHTML;
                                                        // this.page_content_text[id]=ref.textContent;


                                                    }
                                                }}
                                                    id={'page_content_' + id}

                                                    onBeforeInput={(e) => {
                                                        // console.log(e.data);
                                                    }}

                                                    onKeyUp={(e) => {
                                                        document.getElementById('page_content_' + id).innerHTML = this.pages_new[id].value;

                                                        e.stopPropagation();

                                                        e.preventDefault();


                                                    }}
                                                    onBlur={(e) => {
                                                        // this.checkOverflow(0);
                                                        // this.setState({ pages_new:this.pages_new })
                                                    }}


                                                    contentEditable='true' style={{ outline: 0, columnCount: this.state.numero_colunas_page[id]==undefined?this.state.numero_colunas:this.state.numero_colunas_page[id], height: height_sample - (this.state.marginInternaSuperior) - (this.state.marginInternaInferior) - 67 - 20, columnFill: 'auto', paddingLeft: 10, paddingRight: 10 }} >

                                                </div>
                                                <div style={{ position: 'absolute', bottom: 10, right: 0, left: 0 }} >
                                                    <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 12 }}>Página {id + 1} de {this.state.pages_new.length}</p>
                                                </div>


                                            </div>
                                        </div>

                                        {/* <div className='nextpage1'></div> */}
                                    </div>
                                ))}
                            </div>

                            {/* <div style={{position:'absolute',top:-10000000}} id="pdf_diagramado">

                        {this.state.pages.map((item, id) => (
                            <div key={id} style={{width:794,background:'white'}}>
                                <div ref={(ref) => {
                                    item.elements.map((elem, index) => {
                                        elem=elem.cloneNode();
                                        elem.id = 'a'+Math.random();
                                        if (ref != null) {
                                            ref.appendChild(elem.cloneNode());
                                        }
                                    })
                                }} style={{ columnCount: 2, height: 1123, columnFill: 'auto' }} >

                                </div>

                            </div>
                        ))}
                        </div> */}

                        </div>

                    </div>
                </div>

                {/* <iframe id="iframe"></iframe> */}

                <div style={{ width: this.state.numero_colunas == 1 ? (width_one_column - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40)) : (width_two_column), overflow: 'scroll', position: 'absolute', top: -100000 }}>

                    <div style={{ columnCount: 1 }} >
                        {this.state.provaSelect.questoes != undefined && this.state.provaSelect.questoes.map((item, id) => {
                            if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                number_questao = number_questao + 1;
                            }
                            return (
                                <div key={id} style={{ marginTop: id == 0 ? 0 : 20 }} id={"container_" + item.id}>

                                    {/* <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                                        {item.categoria.name}</h6> */}
                                    {this.state.show_category == true && (id == 0 || item.categoria.id != this.state.provaSelect.questoes[id - 1].categoria.id) && <div id={'categoria_' + item.id} style={{ display: 'flex', justifyContent: 'center' }}><div style={{ padding: 5, width: '100%', borderRadius: 0, border: '1px solid black', marginBottom: 15, marginTop: id != 0 ? 0 : 0, background: '#f2f2f2' }}>
                                        <p style={{ textTransform: 'uppercase', textAlign: 'center', color: 'black', fontSize: 18, fontWeight: '500', marginBottom: 0 }}>{item.categoria.name}</p>
                                    </div>
                                    </div>}

                                    {item.type_questao == 'dissertativa' && <div style={{ fontWeight: 'normal' }}>
                                        <div className="">
                                            <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                <b style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }}>{number_questao})</b></h5>
                                        </div>
                                        <div className="" id={'enunciado_' + item.id}>

                                            <div>

                                                <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name) }}>

                                                </div>
                                            </div>

                                        </div>
                                    </div>}



                                    {item.type_questao == 'simples' && <div id={"questao_" + item.id} style={{ fontWeight: 'normal' }}>
                                        <div  >
                                            {/* <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                <b>{number_questao})</b></h5> */}
                                            <div className='col-12 col-sm-12'>
                                                <div style={{ marginTop: 0 }} id={'enunciado_' + item.id}>
                                                    <div>

                                                        <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name.substring(0, item.name.indexOf('>'))) + '><span style="font-weight:bold">' + number_questao + ")  </span>" + this.changeFontSizeQuestoes(item.name.substring(item.name.indexOf('>') + 1, item.name.length - 4)) + '</p>' }}>

                                                        </div>
                                                    </div>

                                                </div>



                                                <div className="">



                                                    <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[0].id}>
                                                        {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                    <p style={{ color: item.correta == 'a' ? 'green' : 'black', fontWeight: item.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                                </div> */}
                                                        <div >

                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[0].name.substring(0, item.alternativas[0].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "A)  </span>" + this.changeFontSizeAlts(item.alternativas[0].name.substring(item.alternativas[0].name.indexOf('>') + 1, item.alternativas[0].name.length - 4)) + '</p>' }}>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    {item.alternativas[1] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[1].id}>
                                                        <div >

                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[1].name.substring(0, item.alternativas[1].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "B)  </span>" + this.changeFontSizeAlts(item.alternativas[1].name.substring(item.alternativas[1].name.indexOf('>') + 1, item.alternativas[1].name.length - 4)) + '</p>' }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item.alternativas[2] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[2].id}>
                                                        <div >

                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[2].name.substring(0, item.alternativas[2].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "C)  </span>" + this.changeFontSizeAlts(item.alternativas[2].name.substring(item.alternativas[2].name.indexOf('>') + 1, item.alternativas[2].name.length - 4)) + '</p>' }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                    {item.alternativas[3] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[3].id}>
                                                        <div >

                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[3].name.substring(0, item.alternativas[3].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "D)  </span>" + this.changeFontSizeAlts(item.alternativas[3].name.substring(item.alternativas[3].name.indexOf('>') + 1, item.alternativas[3].name.length - 4)) + '</p>' }}>

                                                            </div>
                                                        </div>

                                                    </div>}

                                                    {item.alternativas[4] != undefined && <div style={{ marginTop: 0 }} id={'alternativa_' + item.alternativas[4].id}>
                                                        <div >

                                                            <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item.alternativas[4].name.substring(0, item.alternativas[4].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "E)  </span>" + this.changeFontSizeAlts(item.alternativas[4].name.substring(item.alternativas[4].name.indexOf('>') + 1, item.alternativas[4].name.length - 4)) + '</p>' }}>

                                                            </div>
                                                        </div>

                                                    </div>}
                                                </div>

                                                {!(id == this.state.provaSelect.questoes.length - 1) &&
                                                    // <div id={"dividor_" + item.id} style={{ paddingTop: 10, paddingBottom: 10 }} >
                                                    //     <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }}></div>
                                                    // </div>
                                                    <hr id={"dividor_" + item.id} style={{}} />

                                                }

                                            </div>
                                        </div>

                                    </div>


                                    }



                                    {item.type_questao == 'conjunto' && <div id={"questao_" + item.id} style={{ fontWeight: 'normal' }}>

                                        <div className="col-12 col-sm-12">

                                            <div style={{ marginTop: 0 }} id={'enunciado_' + item.id}>
                                                <div>

                                                    <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item.name) }} >

                                                    </div>
                                                </div>

                                            </div>

                                            {/* <div id={"dividor_" + item.id} style={{ paddingTop: 10, paddingBottom: 10 }} >

                                                                    <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }} ></div>
                                                                </div> */}
                                            <hr id={"dividor_" + item.id} style={{}} />

                                            {item.questoes.map((item2, id2) => {
                                                number_questao = number_questao + 1;

                                                return (
                                                    <div key={id2} id={"questao_" + item2.id}>

                                                        <div style={{ fontWeight: 'normal' }}>

                                                            <div className="">

                                                                <div style={{ marginTop: 0 }} id={'enunciado_' + item2.id}>
                                                                    <div>
                                                                        <div style={this.state.fontSizeQuestoes == 'default' ? {} : { fontSize: this.state.fontSizeQuestoes }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeQuestoes(item2.name.substring(0, item2.name.indexOf('>'))) + '><span style="font-weight:bold">' + number_questao + ")  </span>" + this.changeFontSizeQuestoes(item2.name.substring(item2.name.indexOf('>') + 1, item2.name.length - 4)) + '</p>' }}>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div style={{ marginTop: 0 }} id={'alternativa_' + item2.alternativas[0].id}>
                                                                    {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                    <p style={{ color: item2.correta == 'a' ? 'green' : 'black', fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                                </div> */}
                                                                    <div >

                                                                        <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[0].name.substring(0, item2.alternativas[0].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "A)  </span>" + this.changeFontSizeAlts(item2.alternativas[0].name.substring(item2.alternativas[0].name.indexOf('>') + 1, item2.alternativas[0].name.length - 4)) + '</p>' }}>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {item2.alternativas[1] != undefined && <div id={'alternativa_' + item2.alternativas[1].id} style={{ marginTop: 0 }}>
                                                                    <div >

                                                                        <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[1].name.substring(0, item2.alternativas[1].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "B)  </span>" + this.changeFontSizeAlts(item2.alternativas[1].name.substring(item2.alternativas[1].name.indexOf('>') + 1, item2.alternativas[1].name.length - 4)) + '</p>' }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                                {item2.alternativas[2] != undefined && <div id={'alternativa_' + item2.alternativas[2].id} style={{ marginTop: 0 }}>
                                                                    <div >

                                                                        <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[2].name.substring(0, item2.alternativas[2].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "C)  </span>" + this.changeFontSizeAlts(item2.alternativas[2].name.substring(item2.alternativas[2].name.indexOf('>') + 1, item2.alternativas[2].name.length - 4)) + '</p>' }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                                {item2.alternativas[3] != undefined && <div id={'alternativa_' + item2.alternativas[3].id} style={{ marginTop: 0 }}>
                                                                    <div >

                                                                        <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[3].name.substring(0, item2.alternativas[3].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "D)  </span>" + this.changeFontSizeAlts(item2.alternativas[3].name.substring(item2.alternativas[3].name.indexOf('>') + 1, item2.alternativas[3].name.length - 4)) + '</p>' }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}

                                                                {item2.alternativas[4] != undefined && <div id={'alternativa_' + item2.alternativas[4].id} style={{ marginTop: 0 }}>
                                                                    <div >

                                                                        <div style={this.state.fontSizeAlternativas == 'default' ? {} : { fontSize: this.state.fontSizeAlternativas }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.changeFontSizeAlts(item2.alternativas[4].name.substring(0, item2.alternativas[4].name.indexOf('>'))) + '><span style="font-weight:bold">(' + "E)  </span>" + this.changeFontSizeAlts(item2.alternativas[4].name.substring(item2.alternativas[4].name.indexOf('>') + 1, item2.alternativas[4].name.length - 4)) + '</p>' }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                            </div>
                                                        </div>
                                                        {!(id2 == item.questoes.length - 1 && id == this.state.provaSelect.questoes.length - 1) &&
                                                            // <div id={"dividor_" + item2.id} style={{ paddingTop: 10, paddingBottom: 10 }} >
                                                            //     <div style={{ width: '100%', height: 3, background: 'black', marginTop: 0, marginBottom: 0 }} ></div>
                                                            // </div>
                                                            <hr id={"dividor_" + item2.id} style={{}} />

                                                        }


                                                    </div>
                                                )
                                            })}
                                        </div>


                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>






        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ImprimirDiagramacao);


